import { Card, Col, Container, Row } from "react-bootstrap";
import "./ClientCard.css";
import { Link } from "react-router-dom";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
} from "../../../redux/registeredUserRedux";
import { userRequest } from "../../../requestMethod";
import MiniLoader from "../../miniLoader/MiniLoader";
import Popup from "../../popup/Popup";

const ClientCard = ({ clientDetail }) => {
  // State to store the selected POC details
  const [selectedPOC, setSelectedPOC] = useState(
    clientDetail?.poc?.[0] || null
  );

  // useEffect(() => {
  //   setSelectedPOC(clientDetail?.poc?.[0])
  // },[clientDetail])

  console.log("selected poc ===>", selectedPOC);

  // Handle the change in selected POC
  const handlePOCChange = (event) => {
    const selectedPocName = event.target.value;
    console.log("selected poc name ==>", selectedPocName);
    const selectedPocDetails = clientDetail?.poc?.find(
      (poc) => poc.name === selectedPocName
    );
    console.log("selected oic details ==>", selectedPocDetails);
    setSelectedPOC(selectedPocDetails); // Update state with selected POC
  };

  const pocOptions = [
    "SPOC: Ishwariya",
    "Web POC: Lorem",
    "Content POC: Lorem",
    "Graphic POC: Lorem",
    "Digital M. POC: Lorem",
    "Accounts POC: Lorem",
  ];
  // console.log("coming client details ==>", clientDetails);
  const clientEmail = "meritus@gmail.com";
  const clientPhone = "+9876543210";
  const handleEmailClick = () => {
    window.location.href = `mailto:${clientEmail}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${clientPhone}`;
  };

  const setBgColorOfElements = (clientStatus, elementType) => {
    const lowerCaseStatus = clientStatus?.toLowerCase();
    console.log("coming status client ==>", lowerCaseStatus);
    const colorMap = {
      active: {
        clientCodeBgColor: "var(--Green)",
        clientNameBg: "var(--lGreen)",
        clientNameColor: "var(--Green)",
        clientPhoneColor: "var(--Green)",
        clientStatusBg: "var(--Green)",
      },
      maintenance: {
        clientCodeBgColor: "var(--Orange)",
        clientNameBg: "var(--LOrange)",
        clientNameColor: "var(--Orange)",
        clientPhoneColor: "var(--Orange)",
        clientStatusBg: "var(--Orange)",
      },
      former: {
        clientCodeBgColor: "var(--Red)",
        clientNameBg: "var(--LRed)",
        clientNameColor: "var(--Red)",
        clientPhoneColor: "var(--Red)",
        clientStatusBg: "var(--Red)",
      },
      // Default colors if the status is unknown
      default: {
        clientCodeBgColor: "transparent",
        clientNameBg: "transparent",
        clientNameColor: "transparent",
        clientPhoneColor: "transparent",
        clientStatusBg: "transparent",
      },
    };
    return (
      colorMap[lowerCaseStatus]?.[elementType] || colorMap.default[elementType]
    );
  };

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [deletingEmployeeId, setDeletingEmployeeId] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  console.log("active dropdown ==>", activeDropdown);

  const hnadleDropDownClick = (id) => {
    setActiveDropdown((prevState) => (prevState === id ? null : id));
  };

  const deleteClient = async (id, dispatch) => {
    console.log("coming id in api function ==>", id);
    setIsLoaderActive(true);
    setIsPopupActive(false);
    setIsItConfimationPopup(false);
    dispatch(deleteUserStart());
    try {
      const res = await userRequest.delete(`/user/${id}`);
      console.log("coming response after delete ==>", res);
      if (res?.status === 200) {
        dispatch(deleteUserSuccess(id));
        setIsLoaderActive(false);
        setIsItConfimationPopup(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res.data);
      }
    } catch (error) {
      dispatch(deleteUserFailure(true));
      setIsLoaderActive(false);
      setIsItConfimationPopup(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data);
      console.log("getting error when deleting user===>", error);
    }
  };

  const hidePopup = () => {
    setIsPopupActive(false);
    setIsLoaderActive(false);
  };

  const handleDelete = () => {
    console.log("setDeletingEmployeeId =>", deletingEmployeeId);
    deleteClient(deletingEmployeeId, dispatch);
  };

  const handleConfirmPopup = (popupStatus, id) => {
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    setDeletingEmployeeId(id);
  };

  // {
  const clientCodeBgColor = setBgColorOfElements(
    clientDetail.clientStatus,
    "clientCodeBgColor"
  );

  const clientNameBg = setBgColorOfElements(
    clientDetail.clientStatus,
    "clientNameBg"
  );

  const clientNameColor = setBgColorOfElements(
    clientDetail.clientStatus,
    "clientNameColor"
  );

  const clientPhoneColor = setBgColorOfElements(
    clientDetail.clientStatus,
    "clientPhoneColor"
  );

  const clientStatusBg = setBgColorOfElements(
    clientDetail.clientStatus,
    "clientStatusBg"
  );
  // }

  return (
    <>
      {/* // <section className="client-card-wrapper"> */}
      {/* <Container> */}
      {/* <Row> */}
      {/* {clientDetails?.map((clientDetail, index) => { */}

      {/* return ( */}
      <Col lg={4}>
        <Card className="client-card">
          {/* unique client id */}
          <div
            className="cc-uniqueNo-wrapper position-relative"
            style={{
              backgroundColor: clientCodeBgColor,
            }}
          >
            {/* Brand name  */}
            <div className="brand-name">
              <h6 className="m-0">{clientDetail.brandName}</h6>
            </div>
            <>
              <BsThreeDotsVertical
                onClick={() => hnadleDropDownClick(clientDetail?._id)}
              />
              {activeDropdown === clientDetail?._id && (
                <div className="dropdown-optio-wrapper">
                  <ul>
                    {(currentUser?.isUserDepartmentHead || currentUser?.isUserOrganisationOwner) && (
                      <>
                        <Link to={`/edit-client/${clientDetail?._id}`}>
                          <li>Edit</li>
                        </Link>
                        <Link
                          onClick={() =>
                            handleConfirmPopup(true, clientDetail._id)
                          }
                        >
                          <li>Delete</li>
                        </Link>
                      </>
                    )}
                    <Link to={`/all-services/${clientDetail?._id}`}>
                      <li>Services</li>
                    </Link>
                    <Link to={`/all-projects/${clientDetail?._id}`}>
                      <li>Projects</li>
                    </Link>
                  </ul>
                </div>
              )}
            </>
            {/* {currentUser?.isUserUniworldAdmin && (
              <>
                <BsThreeDotsVertical
                  onClick={() => hnadleDropDownClick(clientDetail?._id)}
                />
                {activeDropdown === clientDetail?._id && (
                  <div className="dropdown-optio-wrapper">
                    <ul>
                      <Link to={`/edit-client/${clientDetail?._id}`}>
                        <li>Edit</li>
                      </Link>
                      <Link
                        onClick={() =>
                          handleConfirmPopup(true, clientDetail._id)
                        }
                      >
                        <li>Delete</li>
                      </Link>
                      <Link to={`/all-services/${clientDetail?._id}`}>
                        <li>Services</li>
                      </Link>
                    </ul>
                  </div>
                )}
              </>
            )} */}
          </div>

          {/* <Link to="/all-services"> */}
          <div className="client-card-content-wrapper">
            {/* client profile pic  */}
            <div className="brand-logo">
              {clientDetail.profileImg ? (
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients-logo/${clientDetail.profileImg}`}
                  alt="brand-logo"
                />
              ) : (
                <p>Image not available</p>
              )}
            </div>

            {/* client name  */}
            <div
              className="client-name"
              style={{
                backgroundColor: clientNameBg,
              }}
            >
              <p className="m-0" style={{ color: clientNameColor }}>
                {clientDetail.name}
              </p>
            </div>

            {/* Client POC */}
            <div
              className="client-poc"
              style={{
                backgroundColor: clientNameBg,
              }}
            >
              {clientDetail?.poc?.length > 0 ? (
                <select
                  // value={clientDetail?.poc}
                  value={selectedPOC?.name || ""}
                  onChange={handlePOCChange} // Add onChange handler to update selected POC
                  style={{
                    backgroundColor: clientNameBg,
                  }}
                >
                  {clientDetail?.poc?.map((option, index) => (
                    // console.log("option data ==>", option)
                    <option
                      key={index}
                      value={option?.name}
                      className="text-center"
                    >
                      {`${option?.department} POC ${option?.name}`}
                    </option>
                  ))}
                </select>
              ) : (
                <p>Not available</p>
              )}
            </div>

            {/* employee contact details  */}
            <div className="client-email">
              {selectedPOC?.email ? (
                <Link to="#" onClick={handleEmailClick}>
                  {console.log("selected email==>", selectedPOC?.email)}
                  {selectedPOC?.email}
                </Link>
              ) : (
                <>Email not available</>
              )}
            </div>
            <div className="client-phone">
              {selectedPOC?.contact ? (
                <Link
                  to="#"
                  onClick={handlePhoneClick}
                  style={{ color: clientPhoneColor }}
                >
                  {selectedPOC?.contact}
                </Link>
              ) : (
                <Link to="#" style={{ color: clientPhoneColor }}>
                  Contact not available
                </Link>
              )}
            </div>
          </div>

          {/* client status  */}
          <div
            className="client-status"
            style={{
              backgroundColor: clientStatusBg,
            }}
          >
            <h6 className="m-0">Status: {clientDetail.clientStatus}</h6>
          </div>
        </Card>
      </Col>
      {/* ); */}

      {/* // })} */}
      {/* </Row> */}
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup
          status={popupStatus}
          message={popupText}
          isItConfimationPopup={isItConfimationPopup}
          handleDelete={handleDelete}
          hidePopup={hidePopup}
        />
      )}
      {/* // </Container> */}
      {/* </section> */}
    </>
  );
};

export default ClientCard;
