import { Col, Container, Row, Table } from "react-bootstrap";
import "./SocialMediaNos.css";
import HeaderNav from "../../../emp-dashboard/sidebar/HeaderNav";
import AnnouncementBar from "../../../../common-components/announcement-bar/AnnouncementBar";
import GeneralTable from "../../../../common-components/gen-table/GeneralTable";
import SocialMediaCard from "../../../../common-components/cards/social-media-card/SocialMediaCard";
import BarGraphReport from "../../../../common-components/bar-graph/BarGraphReport";
import TabComponent from "../../../../common-components/tab/TabComponent";
import { headersData, smNumbers } from "../../../../smData";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fileUploadRequest } from "../../../../requestMethod";
import { MdCancel } from "react-icons/md";
import { getAllSMReports } from "../../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";

const SocialMediaNos = () => {

  const { id: clientId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllSMReports(dispatch, clientId)
  }, [clientId]);

  const { isFetching, smReports } = useSelector((state) => state.smReports);

  console.log("smReports", smReports)

  const [currentPlatformData, setCurrentPlatformData] = useState([]);
  const [csvFile, setCSVFile] = useState(null);
  const [isCSVUploaderOpen, setIsCSVUploaderOpen] = useState(false);
  const [isCSVFileUploaded, setIsCSVFileUploaded] = useState(false);
  const [isCSVFileUploading, setIsCSVFileUploading] = useState(false);
  const [isFileSuccess, setIsFileSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");


  const handleChangePlatform = (indexPosition) => {
    setSelectedPlatform(tabData[indexPosition]?.platform)
  }

  const showSelectedPlatformData = (platformType) => {
    const filteredReports = smReports?.filter((item) => item.platformType === platformType);
    setCurrentPlatformData(filteredReports);
  }

  useEffect(() => {
    showSelectedPlatformData(selectedPlatform);
  }, [selectedPlatform])

  const instaColumns = [
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'Reach',
      field: 'reach'
    },
    {
      header: 'Profile Visits',
      field: 'profileVisit'
    },
    {
      header: 'Impressions',
      field: 'impression'
    },
    {
      header: 'Engagement',
      field: 'engagement'
    },
    {
      header: 'New Followers',
      field: 'newFollowers'
    },
    {
      header: 'Total Followers',
      field: 'totalFollowers'
    }
  ];
  const facebookColumns = [
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'Reach',
      field: 'reach'
    },
    {
      header: 'Page Views',
      field: 'profileVisit'
    },
    {
      header: 'New Followers',
      field: 'newFollowers'
    },
    {
      header: 'Total Followers',
      field: 'totalFollowers'
    }
  ];

  const linkedInColumns = [
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'Unique Visitors',
      field: 'uniqueVisitors'
    },
    {
      header: 'Page Views',
      field: 'profileVisit'
    },
    {
      header: 'Impressions',
      field: 'impressions'
    },
    {
      header: 'Engagement Rate (%)',
      field: 'engagement'
    },
    {
      header: 'New Followers',
      field: 'newFollowers'
    },
    {
      header: 'Total Followers',
      field: 'totalFollowers'
    }
  ];
  const twitterColumns = [
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'Unique Visitors',
      field: 'uniqueVisitors'
    },
    {
      header: 'Page Views',
      field: 'profileVisit'
    },
    {
      header: 'Impressions',
      field: 'impressions'
    },
    {
      header: 'Engagement Rate (%)',
      field: 'engagement'
    },
    {
      header: 'New Followers',
      field: 'newFollowers'
    },
    {
      header: 'Total Followers',
      field: 'totalFollowers'
    }
  ];

  const tabData = [
    {
      label: "Instagram",
      platform: "instagram",
      headersData: [
        "Date",
        "Reach",
        "Profile Visits",
        "Impressions",
        "Engagement",
        "New Followers",
        "Total Followers",
      ],
      content: () => (
        <GeneralTable rows={currentPlatformData} columns={instaColumns} />
      ),
    },
    {
      label: "Facebook",
      platform: "facebook",
      headersData: [
        "Date",
        "Reach",
        "Page Views",
        "New Followers",
        "Total Followers",
      ],
      content: () => (
        <GeneralTable rows={currentPlatformData} columns={facebookColumns} />
      ),
    },
    {
      label: "LinkedIn",
      platform: "linkedIn",
      headersData: [
        "Date",
        "Page Views",
        "Unique Visitors",
        "Impressions",
        "Engagement Rate (%)",
        "New Followers",
        "Total Followers",
      ],
      content: () => (
        <GeneralTable rows={currentPlatformData} columns={linkedInColumns} />
      ),
    },
    {
      label: "X (Twitter)",
      platform: "x-twitter",
      headersData: [
        "Date",
        "Impressions",
        "Engagement Rate (%)",
        "New Followers",
        "Total Followers",
      ],
      content: () => (
        <GeneralTable rows={currentPlatformData} columns={twitterColumns} />
      ),
    },
  ];


  // data for social media cards
  const socialMediaStats = [
    {
      platform: "Twitter",
      totalFollowers: 12000,
      newFollowers: 120,
      lostFollowers: 50,
    },
    {
      platform: "Facebook",
      totalFollowers: 18000,
      newFollowers: 130,
      lostFollowers: 60,
    },
    {
      platform: "Instagram",
      totalFollowers: 25000,
      newFollowers: 200,
      lostFollowers: 80,
    },
    {
      platform: "LinkedIn",
      totalFollowers: 10000,
      newFollowers: 90,
      lostFollowers: 30,
    },
  ];

  // data for graph
  const xAxisData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const seriesData = [
    1000, 2000, 1500, 500, 1800, 2500, 1900, 2200, 1300, 1700, 2400, 700,
  ];


  console.log("selectedPlatform ==>", selectedPlatform)

  const handleCSVOpener = () => {
    setIsCSVUploaderOpen(!isCSVUploaderOpen);
    setIsCSVFileUploaded(false);
    setCSVFile(null);
  }

  const fileInputRef = useRef(null);

  const handleChangeCSVFile = (e) => {
    const files = e.target.files
    setCSVFile(files);
    // const file = e.target.files[0];
    // // Check if a valid CSV file is selected
    // if (file && file.name.endsWith('.csv')) {
    //   setCSVFile(file);
    // } else {
    //   setCSVFile(null);
    // }
  }

  const handleAddCSVFile = () => {
    fileInputRef.current.click(); // Trigger file input click event
  };

  const handleCancelUpload = () => {
    handleCSVOpener();
    setCSVFile(null)
  }


  const handleCSVFileUplaod = async (file, selectedPlatform, clientId) => {
    // console.log("coming file ==>", file, selectedPlatform, clientId)
    try {
      setIsCSVFileUploading(true)
      const formData = new FormData();
      formData.append('csvFile', file);
      formData.append('platformType', selectedPlatform);
      formData.append('clientId', clientId);
      // formData.append('platformType', selectedPlatform );
      // formData.append('platformType', selectedPlatform );
      // Append additional data
      // for (const key in selectedPlatform) {
      //   if (selectedPlatform.hasOwnProperty(key)) {
      //     formData.append(key, selectedPlatform[key]);
      //   }
      // }

      const result = await fileUploadRequest.post('/sm-report/upload-via-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("comimng resilts -->", result)
      if (result?.status === 200) {
        setHasError(false);
        setIsCSVFileUploading(false);
        setIsCSVFileUploaded(true);
        setIsFileSuccess(true);
        setSuccessMessage(result?.data?.message)
      }
    } catch (error) {
      console.log("coming error ==>", error)
      setIsCSVFileUploading(false);
      setHasError(true);
      setIsFileSuccess(false);
      if (error?.response?.status === 400) {
        setIsCSVFileUploaded(true);
        setHasErrorMessage(error.response.data.message)
      }

      if (error?.response?.status === 500) {
        setIsCSVFileUploaded(true);
        setHasErrorMessage(error.response.data)
      }
    }
  }

  const handleUpload = () => {
    if (csvFile) {
      handleCSVFileUplaod(csvFile[0], selectedPlatform, clientId); // Pass csvFile to the function
    }
  }

  if (isFetching) {
    return (
      <MiniLoader />
    )
  }


  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12} className="p-0">
            {/* static breadcrumb  */}
            <Breadcrumb mainTitle="Social Media Stats" />
            {/* <Link to='/add-social-media-report'> */}
            <CommonButton btnName="Add Report" btnClass="common-btn float-end mt-3" onClickAction={handleCSVOpener} />
            {/* </Link> */}

            {/* social media platform tab  */}
            {/* <TabComponent
              tabs={tabData}
              rows={smReports}
              columns={instaColumns}
              handleChangeHeader={handleChangePlatform}
            /> */}
            <TabComponent
              tabs={tabData}
              headersData={headersData}
              smNumbers={smNumbers}
              handleChangeHeader={handleChangePlatform}
            />

            {/* Social media KPI Table  */}
            {/* <GeneralTable headers={headersData} smData={smNumbers} /> */}

            {/* Scocial media cards  */}
            {/* <section className="social-media-cards-container">
              {socialMediaStats.map((data, index) => (
                <SocialMediaCard
                  key={index}
                  platform={data.platform}
                  totalFollowers={data.totalFollowers}
                  newFollowers={data.newFollowers}
                  lostFollowers={data.lostFollowers}
                />
              ))}
            </section> */}

            {/* Graph */}
            {/* <section className="sm-no-graph-wrapper">
              <BarGraphReport xAxisData={xAxisData} seriesData={seriesData} />
            </section> */}
          </Col>
        </Row>
      </Container>
      {
        isCSVUploaderOpen && (
          <div className="csv-box-wrapper">
            <div className="csv-box-container">
              <div className="cvs-box-header d-flex justify-content-between">
                <p>Import data via csv file</p>
                <button onClick={handleCSVOpener}>
                  <MdCancel />
                </button>
              </div>
              <div className="cvs-box-middle">
                {
                  isCSVFileUploaded && (
                    <div className="csv-box-output-wrapper">
                      {
                        isFileSuccess && (
                          <>
                            <p className='p-3 text-center'>{successMessage}</p>
                            <p className='p-3 text-center'>Kindly refresh your page</p>
                          </>
                        )
                      }

                      {
                        hasError && (
                          <p className='p-3'>{hasErrorMessage}</p>
                        )
                      }

                    </div>
                  )
                }
                {
                  !isCSVFileUploaded && (
                    csvFile && csvFile[0]?.name ? (
                      <div className="csv-box-selected-file">
                        <strong>Selected file</strong>
                        <p>{csvFile[0]?.name}</p>
                        <button className='custom-btn purple-bg' onClick={handleAddCSVFile}>Replace File</button>
                      </div>
                    ) : (
                      <button onClick={handleAddCSVFile}>Add File</button>
                    )
                  )
                }
                <input type="file" name='csvFile' style={{ display: "none" }} ref={fileInputRef} onChange={handleChangeCSVFile} accept='.csv' />
              </div>
              {
                !isCSVFileUploaded && (
                  <div className="cvs-box-bottom d-flex justify-content-between">
                    <button className='custom-btn purple-bg' onClick={handleUpload}>Upload</button>
                    <button className='custom-btn purple-bg' onClick={handleCancelUpload}>Cancel</button>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
};

export default SocialMediaNos;
