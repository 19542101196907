import AllReports from "../all-reports/AllReports";
import "./AllPerformanceReports.css";

const AllPerformanceReports = () => {
  return (
    <section className="social-media-reports-container">
      <AllReports reportUrl="/performance-marketing-stats" />
    </section>
  );
};

export default AllPerformanceReports;
