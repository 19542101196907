import { createSlice } from "@reduxjs/toolkit";

const smReportReducer = createSlice({
    name: 'smReports',
    initialState: {
        smReports: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getSMReportsStart: (state) => {
            state.isFetching = true;
        },
        getSMReportsSuccess: (state, action) => {
            state.isFetching = false;
            state.smReports = action.payload;
        },
        getSMReportsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addSMReportStart: (state) => {
            state.isFetching = true;
        },

        addSMReportSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.smReports = [...state.smReports, newUser];
        },

        addSMReportFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteSMReportStart: (state) => {
            state.isFetching = true;
        },

        deleteSMReportSuccess: (state, action) => {
            state.isFetching = false;
            state.smReports.splice(
                state.smReports.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteSMReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update user actions
        updateSMReportStart: (state) => {
            state.isFetching = true;
        },

        updateSMReportSuccess: (state, action) => {
            state.isFetching = false;
            const adminIndex = state.smReports.findIndex(admin => admin._id === action.payload._id);
            if (adminIndex !== -1) {
                state.smReports[adminIndex] = action.payload;
            }
        },

        updateSMReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.smReports = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getSMReportsStart, getSMReportsSuccess, getSMReportsFailure, deleteSMReportStart, deleteSMReportSuccess, deleteSMReportFailure, addSMReportFailure, addSMReportStart, addSMReportSuccess, updateSMReportStart, updateSMReportSuccess, updateSMReportFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure } = smReportReducer.actions;
export default smReportReducer.reducer;

