import React, { useEffect, useState } from 'react'
import DynamicForm from '../../common-components/form/DynamicForm'
import { notificationSchema } from '../../formSchema'
import { getGlobalNotification } from '../../redux/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import { serverRequestType } from '../../config'
import MiniLoader from '../../common-components/miniLoader/MiniLoader'

const GlobalNotification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getGlobalNotification(dispatch)
  }, [dispatch]);

  const { notification, isFetching: isGlobalNotificationFetching, } = useSelector(
    (state) => state.globalNotification
  );

  const state = useSelector((state) => state);

  console.log("state from global page ==>", state)


  console.log("notification in jsx ==>", notification)

  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    setInitialValues(notification)
  }, [notification])

  //     schema,
  //   httpMethod,
  //   apiEndPoint,
  //   buttonName,
  //   userType

  if(isGlobalNotificationFetching) {
    return(
      <MiniLoader />
    )
  }

  return (
    <>
      <div>
        <h2>Update Notification</h2>
      </div>
      <DynamicForm
        schema={notificationSchema}
        initialValues={initialValues}
        httpMethod={serverRequestType.POST}
        apiEndPoint={`global-notification/create`}
        buttonName="Update"
      />
    </>
  )
}

export default GlobalNotification
