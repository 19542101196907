import { useCurrentUser } from './useCurrentUser';

const useOrganisationId = () => {
    // const user = useSelector((state) => state.loginUser?.currentUser)
    const user = useCurrentUser();
    const organisationId = user?.isUserOrganisationOwner ? user._id : user?.organisationId;
    return organisationId;
}

export default useOrganisationId
