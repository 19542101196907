import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Breadcrumb = (props) => {
  return (
    <section className={`static-breadcrumb-wrapper ${props.customClass}`}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="sb-content-wrapper">
              <h2>{props.mainTitle}</h2>
              <p className="m-0">Dashboard&nbsp;/&nbsp;{props.mainTitle}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Breadcrumb;
