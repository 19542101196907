import { Col, Container, Row } from "react-bootstrap";
import "./AllProjects.css";
import Project from "../../../../common-components/cards/project-card/Project";
import useCurrentUser from "../../../../custom-hooks/useCurrentUser";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { getAllProjects } from "../../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";

const AllProjects = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const { id: clientId } = useParams();
  console.log("client id from project ==>", clientId)

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  const { isFetching, projects: allProjects } = useSelector(
    (state) => state.projects
  );

  const [filteredProjects, setFilteredProjects] = useState([allProjects]);



  useEffect(() => {
    if (clientId) {
      setFilteredProjects(allProjects?.filter((project) => project?.clientId === clientId))
    } else {
      setFilteredProjects(allProjects)
    }
  }, [clientId, allProjects])

  console.log("all projects ==>", allProjects)


  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12} className="p-0">
            {/* breadcrumb */}
            <Breadcrumb mainTitle="All Projects" />

            {/* Projects  */}
            <div className="all-projects-container custom-padding">
              {(currentUser?.isUserUniworldAdmin ||
                currentUser?.isUserDepartmentHead) && (
                  <div className="action-btn-wrapper cp-card-section">
                    <Link to={"/add-project"}>
                      <CommonButton
                        btnName="Add Project"
                        btnClass="btn-edit common-btn"
                      />
                    </Link>
                  </div>
                )}

              <Project projects={filteredProjects} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default AllProjects;
