import GeneralTable from "./common-components/gen-table/GeneralTable";

export const headersData = [
  "Date",
  "Impressions",
  "Page View",
  "Unique Visitor",
  "Engagement Rate",
  "Reach",
  "Engagement",
];
// tabs data

export const tabData = [
  {
    label: "Instagram",
    platform: "instagram",
    headersData: [
      "Date",
      "Reach",
      "Profile Visits",
      "Impressions",
      "Engagement",
      "New Followers",
      "Total Followers",
    ],
    content: ({ headersData, smData }) => (
      <GeneralTable headers={headersData} smData={smData} />
    ),
  },
  {
    label: "Facebook",
    platform: "facebook",
    headersData: [
      "Date",
      "Reach",
      "Page Views",
      "New Followers",
      "Total Followers",
    ],
    content: ({ headersData, smData }) => (
      <GeneralTable headers={headersData} smData={smData} />
    ),
  },
  {
    label: "LinkedIn",
    platform: "linkedIn",
    headersData: [
      "Date",
      "Page Views",
      "Unique Visitors",
      "Impressions",
      "Engagement Rate (%)",
      "New Followers",
      "Total Followers",
    ],
    content: ({ headersData, smData }) => (
      <GeneralTable headers={headersData} smData={smData} />
    ),
  },
  {
    label: "X (Twitter)",
    platform: "x-twitter",
    headersData: [
      "Date",
      "Impressions",
      "Engagement Rate (%)",
      "New Followers",
      "Total Followers",
    ],
    content: ({ headersData, smData }) => (
      <GeneralTable headers={headersData} smData={smData} />
    ),
  },
];

export const smNumbers = [
  {
    date: "19-04-2024",
    Reach: 1500,
    "Page Views": 200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "19-04-2024",
    Reach: 1080,
    "Profile Visits": 440,
    Impressions: 880,
    Engagement: 110,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "19-04-2024",
    "Page Views": 404,
    "Unique Visitors": 259,
    Impressions: 880,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "19-04-2024",
    Impressions: 700,
    "Engagement Rate (%)": 0.6,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "20-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "20-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "20-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "20-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "21-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "21-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "21-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "21-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "22-04-2024",
    Reach: 11000,
    "Page Views": 520,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "22-04-2024",
    Reach: 3900,
    "Profile Visits": 350,
    Impressions: 9000,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "22-04-2024",
    "Page Views": 250,
    "Unique Visitors": 450,
    Impressions: 930,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "22-04-2024",
    Impressions: 960,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "23-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "23-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "23-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "23-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "24-04-2024",
    Reach: 11000,
    "Page Views": 520,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "24-04-2024",
    Reach: 3900,
    "Profile Visits": 350,
    Impressions: 9000,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "24-04-2024",
    "Page Views": 250,
    "Unique Visitors": 450,
    Impressions: 930,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "24-04-2024",
    Impressions: 960,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "25-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "25-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "25-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "25-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "26-04-2024",
    Reach: 11000,
    "Page Views": 520,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "26-04-2024",
    Reach: 3900,
    "Profile Visits": 350,
    Impressions: 9000,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "26-04-2024",
    "Page Views": 250,
    "Unique Visitors": 450,
    Impressions: 930,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "26-04-2024",
    Impressions: 960,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "27-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "27-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "27-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "27-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "28-04-2024",
    Reach: 11000,
    "Page Views": 520,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "28-04-2024",
    Reach: 3900,
    "Profile Visits": 350,
    Impressions: 9000,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "28-04-2024",
    "Page Views": 250,
    "Unique Visitors": 450,
    Impressions: 930,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "28-04-2024",
    Impressions: 960,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "29-04-2024",
    Reach: 1080,
    "Page Views": 450,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "29-04-2024",
    Reach: 1110,
    "Profile Visits": 150,
    Impressions: 790,
    Engagement: 120,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "29-04-2024",
    "Page Views": 360,
    "Unique Visitors": 310,
    Impressions: 1100,
    "Engagement Rate (%)": 0.19,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "29-04-2024",
    Impressions: 850,
    "Engagement Rate (%)": 0.12,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "30-04-2024",
    Reach: 11000,
    "Page Views": 520,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "30-04-2024",
    Reach: 3900,
    "Profile Visits": 350,
    Impressions: 9000,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "30-04-2024",
    "Page Views": 250,
    "Unique Visitors": 450,
    Impressions: 930,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "30-04-2024",
    Impressions: 960,
    "Engagement Rate (%)": 0.5,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "01-05-2024",
    Reach: 1120,
    "Page Views": 160,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "02-05-2024",
    Reach: 1450,
    "Profile Visits": 370,
    Impressions: 1150,
    Engagement: 180,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "03-05-2024",
    "Page Views": 280,
    "Unique Visitors": 290,
    Impressions: 860,
    "Engagement Rate (%)": 0.13,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "04-05-2024",
    Impressions: 2300,
    "Engagement Rate (%)": 0.17,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "05-05-2024",
    Reach: 4000,
    "Page Views": 360,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "06-05-2024",
    Reach: 1800,
    "Profile Visits": 260,
    Impressions: 950,
    Engagement: 210,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "07-05-2024",
    "Page Views": 500,
    "Unique Visitors": 310,
    Impressions: 980,
    "Engagement Rate (%)": 0.52,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "08-05-2024",
    Impressions: 1060,
    "Engagement Rate (%)": 0.65,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "09-05-2024",
    Reach: 1130,
    "Page Views": 170,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "10-05-2024",
    Reach: 1500,
    "Profile Visits": 380,
    Impressions: 1200,
    Engagement: 190,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "11-05-2024",
    "Page Views": 290,
    "Unique Visitors": 300,
    Impressions: 870,
    "Engagement Rate (%)": 0.14,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "12-05-2024",
    Impressions: 2400,
    "Engagement Rate (%)": 0.18,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "13-05-2024",
    Reach: 4100,
    "Page Views": 380,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "14-05-2024",
    Reach: 2000,
    "Profile Visits": 270,
    Impressions: 970,
    Engagement: 200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "15-05-2024",
    "Page Views": 550,
    "Unique Visitors": 320,
    Impressions: 1000,
    "Engagement Rate (%)": 0.6,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "16-05-2024",
    Impressions: 1090,
    "Engagement Rate (%)": 0.68,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "17-05-2024",
    Reach: 1150,
    "Page Views": 180,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "18-05-2024",
    "Page Views": 1280,
    "Unique Visitors": 580,
    Impressions: 1000,
    "Engagement Rate (%)": 0.78,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "19-05-2024",
    Reach: 1100,
    "Page Views": 300,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "20-05-2024",
    Reach: 11600,
    "Profile Visits": 550,
    Impressions: 2500,
    Engagement: 1200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "21-05-2024",
    Impressions: 9300,
    "Engagement Rate (%)": 0.37,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "22-05-2024",
    "Page Views": 280,
    "Unique Visitors": 480,
    Impressions: 1000,
    "Engagement Rate (%)": 0.48,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "23-05-2024",
    Reach: 1240,
    "Page Views": 890,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "24-05-2024",
    Reach: 1160,
    "Page Views": 490,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "25-05-2024",
    Impressions: 7300,
    "Engagement Rate (%)": 0.47,
    "New Followers": 30,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "26-05-2024",
    Reach: 1250,
    "Page Views": 400,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "27-05-2024",
    Reach: 1120,
    "Page Views": 310,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "28-05-2024",
    Reach: 11600,
    "Profile Visits": 550,
    Impressions: 2500,
    Engagement: 1200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "29-05-2024",
    Impressions: 9400,
    "Engagement Rate (%)": 0.39,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "30-05-2024",
    Reach: 2100,
    "Page Views": 290,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "31-05-2024",
    "Page Views": 280,
    "Unique Visitors": 480,
    Impressions: 1000,
    "Engagement Rate (%)": 0.48,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "LinkedIn",
  },
  {
    date: "01-06-2024",
    Reach: 1260,
    "Profile Visits": 530,
    Impressions: 1040,
    Engagement: 200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
  {
    date: "02-06-2024",
    Impressions: 1110,
    "Engagement Rate (%)": 0.72,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "03-06-2024",
    Reach: 1190,
    "Page Views": 200,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "04-06-2024",
    Reach: 1650,
    "Page Views": 410,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "05-06-2024",
    Reach: 1140,
    "Page Views": 320,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "06-06-2024",
    Reach: 1600,
    "Profile Visits": 50,
    Impressions: 500,
    Engagement: 200,
    "New Followers": 20,
    "Total Followers": 9000,
    platform: "Instagram",
  },
  {
    date: "07-06-2024",
    Impressions: 9500,
    "Engagement Rate (%)": 0.4,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "08-06-2024",
    Reach: 2200,
    "Page Views": 300,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "09-06-2024",
    Reach: 1280,
    "Page Views": 540,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "10-06-2024",
    Reach: 1160,
    "Page Views": 350,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "11-06-2024",
    Reach: 10200,
    "Profile Visits": 350,
    Impressions: 2900,
    Engagement: 600,
    "New Followers": 17,
    "Total Followers": 5400,
    platform: "Instagram",
  },
  {
    date: "12-06-2024",
    Impressions: 9600,
    "Engagement Rate (%)": 0.42,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "X (Twitter)",
  },
  {
    date: "13-06-2024",
    Reach: 2300,
    "Page Views": 310,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "14-06-2024",
    Reach: 1320,
    "Page Views": 480,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "15-06-2024",
    Reach: 1180,
    "Page Views": 370,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Facebook",
  },
  {
    date: "16-06-2024",
    Reach: 12000,
    "Profile Visits": 550,
    Impressions: 2600,
    Engagement: 1500,
    "New Followers": 50,
    "Total Followers": 10000,
    platform: "Instagram",
  },
];











// export const tabData = [
//   {
//     label: "Instagram",
//     platform: "instagram",
//     headersData: [
//       "Date",
//       "Reach",
//       "Profile Visits",
//       "Impressions",
//       "Engagement",
//       "New Followers",
//       "Total Followers",
//     ],
//     content: ({ headersData, smData }) => (
//       <GeneralTable headers={headersData} smData={smData} />
//     ),
//   },
//   {
//     label: "Facebook",
//     platform: "facebook",
//     headersData: [
//       "Date",
//       "Reach",
//       "Page Views",
//       "New Followers",
//       "Total Followers",
//     ],
//     content: ({ headersData, smData }) => (
//       <GeneralTable headers={headersData} smData={smData} />
//     ),
//   },
//   {
//     label: "LinkedIn",
//     platform: "linkedIn",
//     headersData: [
//       "Date",
//       "Page Views",
//       "Unique Visitors",
//       "Impressions",
//       "Engagement Rate (%)",
//       "New Followers",
//       "Total Followers",
//     ],
//     content: ({ headersData, smData }) => (
//       <GeneralTable headers={headersData} smData={smData} />
//     ),
//   },
//   {
//     label: "X (Twitter)",
//     platform: "x-twitter",
//     headersData: [
//       "Date",
//       "Impressions",
//       "Engagement Rate (%)",
//       "New Followers",
//       "Total Followers",
//     ],
//     content: ({ headersData, smData }) => (
//       <GeneralTable headers={headersData} smData={smData} />
//     ),
//   },
// ];

// export const smNumbers = [
//   {
//     date: "19-04-2024",
//     Reach: 1500,
//     "Page Views": 200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "19-04-2024",
//     Reach: 1080,
//     "Profile Visits": 440,
//     Impressions: 880,
//     Engagement: 110,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "19-04-2024",
//     "Page Views": 404,
//     "Unique Visitors": 259,
//     Impressions: 880,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "19-04-2024",
//     Impressions: 700,
//     "Engagement Rate (%)": 0.6,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "20-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "20-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "20-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "20-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "21-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "21-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "21-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "21-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "22-04-2024",
//     Reach: 11000,
//     "Page Views": 520,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "22-04-2024",
//     Reach: 3900,
//     "Profile Visits": 350,
//     Impressions: 9000,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "22-04-2024",
//     "Page Views": 250,
//     "Unique Visitors": 450,
//     Impressions: 930,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "22-04-2024",
//     Impressions: 960,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "23-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "23-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "23-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "23-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "24-04-2024",
//     Reach: 11000,
//     "Page Views": 520,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "24-04-2024",
//     Reach: 3900,
//     "Profile Visits": 350,
//     Impressions: 9000,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "24-04-2024",
//     "Page Views": 250,
//     "Unique Visitors": 450,
//     Impressions: 930,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "24-04-2024",
//     Impressions: 960,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "25-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "25-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "25-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "25-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "26-04-2024",
//     Reach: 11000,
//     "Page Views": 520,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "26-04-2024",
//     Reach: 3900,
//     "Profile Visits": 350,
//     Impressions: 9000,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "26-04-2024",
//     "Page Views": 250,
//     "Unique Visitors": 450,
//     Impressions: 930,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "26-04-2024",
//     Impressions: 960,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "27-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "27-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "27-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "27-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "28-04-2024",
//     Reach: 11000,
//     "Page Views": 520,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "28-04-2024",
//     Reach: 3900,
//     "Profile Visits": 350,
//     Impressions: 9000,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "28-04-2024",
//     "Page Views": 250,
//     "Unique Visitors": 450,
//     Impressions: 930,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "28-04-2024",
//     Impressions: 960,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "29-04-2024",
//     Reach: 1080,
//     "Page Views": 450,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "29-04-2024",
//     Reach: 1110,
//     "Profile Visits": 150,
//     Impressions: 790,
//     Engagement: 120,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "29-04-2024",
//     "Page Views": 360,
//     "Unique Visitors": 310,
//     Impressions: 1100,
//     "Engagement Rate (%)": 0.19,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "29-04-2024",
//     Impressions: 850,
//     "Engagement Rate (%)": 0.12,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "30-04-2024",
//     Reach: 11000,
//     "Page Views": 520,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "30-04-2024",
//     Reach: 3900,
//     "Profile Visits": 350,
//     Impressions: 9000,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "30-04-2024",
//     "Page Views": 250,
//     "Unique Visitors": 450,
//     Impressions: 930,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "30-04-2024",
//     Impressions: 960,
//     "Engagement Rate (%)": 0.5,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "01-05-2024",
//     Reach: 1120,
//     "Page Views": 160,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "02-05-2024",
//     Reach: 1450,
//     "Profile Visits": 370,
//     Impressions: 1150,
//     Engagement: 180,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "03-05-2024",
//     "Page Views": 280,
//     "Unique Visitors": 290,
//     Impressions: 860,
//     "Engagement Rate (%)": 0.13,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "04-05-2024",
//     Impressions: 2300,
//     "Engagement Rate (%)": 0.17,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "05-05-2024",
//     Reach: 4000,
//     "Page Views": 360,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "06-05-2024",
//     Reach: 1800,
//     "Profile Visits": 260,
//     Impressions: 950,
//     Engagement: 210,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "07-05-2024",
//     "Page Views": 500,
//     "Unique Visitors": 310,
//     Impressions: 980,
//     "Engagement Rate (%)": 0.52,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "08-05-2024",
//     Impressions: 1060,
//     "Engagement Rate (%)": 0.65,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "09-05-2024",
//     Reach: 1130,
//     "Page Views": 170,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "10-05-2024",
//     Reach: 1500,
//     "Profile Visits": 380,
//     Impressions: 1200,
//     Engagement: 190,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "11-05-2024",
//     "Page Views": 290,
//     "Unique Visitors": 300,
//     Impressions: 870,
//     "Engagement Rate (%)": 0.14,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "12-05-2024",
//     Impressions: 2400,
//     "Engagement Rate (%)": 0.18,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "13-05-2024",
//     Reach: 4100,
//     "Page Views": 380,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "14-05-2024",
//     Reach: 2000,
//     "Profile Visits": 270,
//     Impressions: 970,
//     Engagement: 200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "15-05-2024",
//     "Page Views": 550,
//     "Unique Visitors": 320,
//     Impressions: 1000,
//     "Engagement Rate (%)": 0.6,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "16-05-2024",
//     Impressions: 1090,
//     "Engagement Rate (%)": 0.68,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "17-05-2024",
//     Reach: 1150,
//     "Page Views": 180,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "18-05-2024",
//     "Page Views": 1280,
//     "Unique Visitors": 580,
//     Impressions: 1000,
//     "Engagement Rate (%)": 0.78,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "19-05-2024",
//     Reach: 1100,
//     "Page Views": 300,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "20-05-2024",
//     Reach: 11600,
//     "Profile Visits": 550,
//     Impressions: 2500,
//     Engagement: 1200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "21-05-2024",
//     Impressions: 9300,
//     "Engagement Rate (%)": 0.37,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "22-05-2024",
//     "Page Views": 280,
//     "Unique Visitors": 480,
//     Impressions: 1000,
//     "Engagement Rate (%)": 0.48,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "23-05-2024",
//     Reach: 1240,
//     "Page Views": 890,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "24-05-2024",
//     Reach: 1160,
//     "Page Views": 490,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "25-05-2024",
//     Impressions: 7300,
//     "Engagement Rate (%)": 0.47,
//     "New Followers": 30,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "26-05-2024",
//     Reach: 1250,
//     "Page Views": 400,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "27-05-2024",
//     Reach: 1120,
//     "Page Views": 310,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "28-05-2024",
//     Reach: 11600,
//     "Profile Visits": 550,
//     Impressions: 2500,
//     Engagement: 1200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "29-05-2024",
//     Impressions: 9400,
//     "Engagement Rate (%)": 0.39,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "30-05-2024",
//     Reach: 2100,
//     "Page Views": 290,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "31-05-2024",
//     "Page Views": 280,
//     "Unique Visitors": 480,
//     Impressions: 1000,
//     "Engagement Rate (%)": 0.48,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "LinkedIn",
//   },
//   {
//     date: "01-06-2024",
//     Reach: 1260,
//     "Profile Visits": 530,
//     Impressions: 1040,
//     Engagement: 200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
//   {
//     date: "02-06-2024",
//     Impressions: 1110,
//     "Engagement Rate (%)": 0.72,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "03-06-2024",
//     Reach: 1190,
//     "Page Views": 200,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "04-06-2024",
//     Reach: 1650,
//     "Page Views": 410,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "05-06-2024",
//     Reach: 1140,
//     "Page Views": 320,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "06-06-2024",
//     Reach: 1600,
//     "Profile Visits": 50,
//     Impressions: 500,
//     Engagement: 200,
//     "New Followers": 20,
//     "Total Followers": 9000,
//     platform: "Instagram",
//   },
//   {
//     date: "07-06-2024",
//     Impressions: 9500,
//     "Engagement Rate (%)": 0.4,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "08-06-2024",
//     Reach: 2200,
//     "Page Views": 300,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "09-06-2024",
//     Reach: 1280,
//     "Page Views": 540,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "10-06-2024",
//     Reach: 1160,
//     "Page Views": 350,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "11-06-2024",
//     Reach: 10200,
//     "Profile Visits": 350,
//     Impressions: 2900,
//     Engagement: 600,
//     "New Followers": 17,
//     "Total Followers": 5400,
//     platform: "Instagram",
//   },
//   {
//     date: "12-06-2024",
//     Impressions: 9600,
//     "Engagement Rate (%)": 0.42,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "X (Twitter)",
//   },
//   {
//     date: "13-06-2024",
//     Reach: 2300,
//     "Page Views": 310,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "14-06-2024",
//     Reach: 1320,
//     "Page Views": 480,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "15-06-2024",
//     Reach: 1180,
//     "Page Views": 370,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Facebook",
//   },
//   {
//     date: "16-06-2024",
//     Reach: 12000,
//     "Profile Visits": 550,
//     Impressions: 2600,
//     Engagement: 1500,
//     "New Followers": 50,
//     "Total Followers": 10000,
//     platform: "Instagram",
//   },
// ];