import "bootstrap/dist/css/bootstrap.css";
import "../footer/Footer.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <section className={`main-footer ${props.CustomClassFooter}`}>
      <Container>
        <footer className="footer-wrapper">
          <div className="copyright-content">
            <p>
              All Rights Reserved &#169;{new Date().getFullYear()} Uniworld
              Studios Pvt. Ltd.
            </p>
          </div>

          <div className="policy-pages">
            <Link to={""}>Terms of Use</Link>
            <Link to={""}>Privacy Policy</Link>
            <Link to={""}>Copyright Policy</Link>
          </div>
        </footer>
      </Container>
    </section>
  );
};

export default Footer;
