import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import "./CommonButton.css";

const CommonButton = (props) => {
  return (
    <Button
      onClick={props.onClickAction}
      variant="primary"
      className={props.btnClass}
      disabled={props.isDisable}
    >
      {props.btnName}
    </Button>
  );
};

export default CommonButton;
