import { Card, Col, Container, Row } from "react-bootstrap";
import "./LeavesOverview.css";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link } from "react-router-dom";
import GeneralTable from "../../../../common-components/gen-table/GeneralTable";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { getAllLeaves } from "../../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";

const LeavesOverview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllLeaves(dispatch);
  }, [dispatch]);

  const allLeaves = useSelector((state) => state.leave.leaves);
  console.log("All leaves ==>", allLeaves);

  // const leaveTableHeader = ["S. No.", "Date", "Type of Leave", "Reason"];


  const leaveTableHeader = [
    {
      headerName : "Date",
      valueToBeRender : "date",
      // renderFunction : (params) => {
      //   return changeDateFormat(params.value)
      // },
    },
    {
      headerName : "Type of Leave",
      valueToBeRender : "typeOfLeave",
    },
    {
      headerName : "Reason",
      valueToBeRender : "reason",
    },
    // {
    //   headerName : "Out Time",
    //   valueToBeRender : "logOffTime",
    // },
  ];

  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [remainingWFH, setRemainingWFH] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(0);

  useEffect(() => {
    setRemainingWFH(allLeaves?.remainingWFH);
    setRemainingLeaves(allLeaves?.remainingLeaves);
    setPendingRequest(allLeaves?.pendingRequest);
  },[allLeaves])




  const formatLeaveData = (leaves) => {
    let formattedData = [];
    leaves?.forEach((leave) => {
      leave.days.forEach((day, dayIndex) => {
        const date = new Date(leave.startDate);
        date.setDate(date.getDate() + dayIndex);
        formattedData.push({
          serialNumber: formattedData.length + 1,
          date: `${date.toLocaleDateString()}`,
          typeOfLeave: day.typeOfLeave,
          reason: leave.reason,
        });
      });
    });
    return formattedData;
  };

  const formattedLeavesData = formatLeaveData(allLeaves?.leavesData);

  console.log("formatted data ==>", formattedLeavesData)

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Leaves" />

      {/* Leaves/WFH section  */}
      <section className="leaves-cards-container custom-padding">
        <Container>
          <Row className="gx-5 justify-content-center">
            {/* total leaves card  */}
            <Col lg={3} md={6} sm={12}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="leave-wfh-count">
                    <p>15</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Total Leaves</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* total WFH  */}
            <Col lg={3} md={6} sm={12}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="leave-wfh-count">
                    <p>15</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Total WFH</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Available leaves  */}
            <Col lg={3} md={6} sm={12}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="leave-wfh-count lwc-green">
                    <p>{remainingLeaves}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Available Leaves</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Available WFH  */}
            <Col lg={3} md={6} sm={12}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="leave-wfh-count lwc-green">
                    <p>{remainingWFH}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Available WFH</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* Pending leave requests  */}
            <Col lg={3} md={6} sm={12}>
              <Card className="leave-wfh-card">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="leave-wfh-count plr-orange">
                    <p>{pendingRequest}</p>
                  </div>
                  <div className="leave-wfh-text">
                    <p>Pending Leave Request</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="leave-apply-btn-container text-center">
        <Link to="/leave-application-form">
          <CommonButton
            btnName="Apply Now"
            btnClass="leave-apply-btn common-btn"
          />
        </Link>
      </div>

      {/* Leave/WFH table  */}
      <div className="leave-wfh-table-container custom-padding mb-50">
        <GeneralTable headers={leaveTableHeader} tableData={formattedLeavesData} />
      </div>
    </>
  );
};

export default LeavesOverview;
