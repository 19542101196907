import { Col, Container, Dropdown, Row } from "react-bootstrap";
import "./SocialMediaPlanForm.css";
import { FaRegSave } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import CommonButton from "../../../../common-components/button/CommonButton";
import { useState } from "react";

const SocialMediaPlanForm = ({ formData, handleChange, addPlan }) => {
  const [errors, setErrors] = useState({}); // to track errors

  const handleCalFormDropdownChange = (name, value) => {
    handleChange({ target: { name, value } });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = [
      "brand",
      "contentBucket",
      "intent",
      "description",
      "typeOfPost",
      "socialMediaPlatform",
    ];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .trim()} field is required.`;
      }
    });

    // If there are errors, set the errors state and do not proceed
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear errors if there are no validation issues
    setErrors({});

    // Add the plan
    addPlan();
  };

  // options for the dropdowns
  const intentOptions = [
    "Brand",
    "Knowledge Sharing",
    "Entertain",
    "Inspire",
    "Convince",
    "Sales",
    "Engage",
    "Special Days",
  ];
  const typeOfPostOptions = [
    "Single Image",
    "Carousel",
    "Reel",
    "Video",
    "Text",
    "Poll",
  ];
  const statusOptions = [
    "Rejected",
    "Delayed",
    "Posted",
    "Scheduled",
    "Approved",
    "Under Process",
  ];

  return (
    <section className="social-media-planning-form">
      <Container>
        <Row>
          <Col lg={12}>
            <form className="planning-form">
              <Row>
                {/* Brand  */}
                <Col lg={4}>
                  <label>Brand:</label>
                  <input
                    type="text"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    required
                  />
                  {errors.brand && (
                    <div className="plan-form-error">{errors.brand}</div>
                  )}
                </Col>

                {/* content bucket  */}
                <Col lg={4}>
                  <label>Content Bucket:</label>
                  <input
                    type="text"
                    name="contentBucket"
                    value={formData.contentBucket}
                    onChange={handleChange}
                    required
                  />
                  {errors.contentBucket && (
                    <div className="plan-form-error">
                      {errors.contentBucket}
                    </div>
                  )}
                </Col>

                {/* intent  */}
                <Col lg={4}>
                  <label>Intent:</label>
                  <Dropdown
                    onSelect={(value) =>
                      handleCalFormDropdownChange("intent", value)
                    }
                    className="cal-form-dropdown"
                  >
                    <Dropdown.Toggle variant="secondary" id="intent-dropdown">
                      {formData.intent || "Select Intent"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="cal-dropdown-options">
                      {intentOptions.map((option, index) => (
                        <Dropdown.Item key={index} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.intent && (
                    <div className="plan-form-error">{errors.intent}</div>
                  )}
                </Col>

                {/* description  */}
                <Col lg={4}>
                  <label>Description:</label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                  {errors.description && (
                    <div className="plan-form-error">{errors.description}</div>
                  )}
                </Col>

                {/* references */}
                <Col lg={4}>
                  <label>References:</label>
                  <input
                    type="text"
                    name="references"
                    value={formData.references}
                    onChange={handleChange}
                  />
                </Col>

                {/* approved references */}
                <Col lg={4}>
                  <label>Approved References:</label>
                  <input
                    type="text"
                    name="approvedReferences"
                    value={formData.approvedReferences}
                    onChange={handleChange}
                  />
                </Col>

                {/* type of post  */}
                <Col lg={4}>
                  <label>Type of Post:</label>
                  <Dropdown
                    onSelect={(value) =>
                      handleCalFormDropdownChange("typeOfPost", value)
                    }
                    className="cal-form-dropdown"
                  >
                    <Dropdown.Toggle
                      variant="secondary"
                      id="typeOfPost-dropdown"
                    >
                      {formData.typeOfPost || "Select Type of Post"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="cal-dropdown-options">
                      {typeOfPostOptions.map((option, index) => (
                        <Dropdown.Item key={index} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.typeOfPostOptions && (
                    <div className="plan-form-error">
                      {errors.typeOfPostOptions}
                    </div>
                  )}
                </Col>

                {/* taget audience  */}
                <Col lg={4}>
                  <label>Target Audience:</label>
                  <input
                    type="text"
                    name="targetAudience"
                    value={formData.targetAudience}
                    onChange={handleChange}
                  />
                </Col>

                {/* hook for audience  */}
                <Col lg={4}>
                  <label>Hook for Audience:</label>
                  <input
                    type="text"
                    name="hookForAudience"
                    value={formData.hookForAudience}
                    onChange={handleChange}
                  />
                </Col>

                {/* social media platform  */}
                <Col lg={4}>
                  <label>Social Media Platform:</label>
                  <input
                    type="text"
                    name="socialMediaPlatform"
                    value={formData.socialMediaPlatform}
                    onChange={handleChange}
                    required
                  />
                  {errors.socialMediaPlatform && (
                    <div className="plan-form-error">
                      {errors.socialMediaPlatform}
                    </div>
                  )}
                </Col>

                {/* taglines  */}
                <Col lg={4}>
                  <label>Taglines:</label>
                  <input
                    type="text"
                    name="tagline"
                    value={formData.tagline}
                    onChange={handleChange}
                  />
                </Col>

                {/* captions  */}
                <Col lg={4}>
                  <label>Captions:</label>
                  <input
                    type="text"
                    name="caption"
                    value={formData.caption}
                    onChange={handleChange}
                  />
                </Col>

                {/* hashtags  */}
                <Col lg={4}>
                  <label>Hashtags:</label>
                  <input
                    type="text"
                    name="hashtags"
                    value={formData.hashtags}
                    onChange={handleChange}
                  />
                </Col>

                {/* Creative  */}
                <Col lg={4}>
                  <label>Creative:</label>
                  <input
                    type="file"
                    accept="image/*,video/*"
                    name="creative"
                    value={formData.creative}
                    onChange={handleChange}
                  />
                </Col>

                {/* status  */}
                <Col lg={4}>
                  <label>Status:</label>
                  <Dropdown
                    onSelect={(value) =>
                      handleCalFormDropdownChange("status", value)
                    }
                    className="cal-form-dropdown"
                  >
                    <Dropdown.Toggle variant="secondary" id="status-dropdown">
                      {formData.status || "Select Status"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="cal-dropdown-options">
                      {statusOptions.map((option, index) => (
                        <Dropdown.Item key={index} eventKey={option}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <input
                    type="te"
                    accept="image/*,video/*"
                    name="creative"
                    value={formData.creative}
                    onChange={handleChange}
                  /> */}
                </Col>

                <Col lg={12} className="text-end">
                  <CommonButton
                    type="submit"
                    onClickAction={handleSubmit}
                    btnClass="save-plan-btn common-btn"
                    btnName="Save Plan"
                  />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SocialMediaPlanForm;
