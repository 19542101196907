import { Col, Container, Row } from "react-bootstrap";
import "./Meetings.css";
import MeetingCard from "../../../common-components/cards/meeting-card/MeetingCard";
import OnlineMeeting from "../../../common-components/online-meeting/OnlineMeeting";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";

const Meetings = () => {
  // meeting data
  const meetingData = [
    {
      status: "upcoming",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
    {
      status: "today",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
  ];

  return (
    <>
      {/* Meetings  */}
      <Breadcrumb mainTitle="Upcoming Meetings" />
      <MeetingCard meetings={meetingData} />
      <OnlineMeeting />
    </>
  );
};

export default Meetings;
