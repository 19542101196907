import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./WelcomeMsg.css";
import { Col, Container, Row } from "react-bootstrap";
import useCurrentUser from "../../custom-hooks/useCurrentUser";

const WelcomeMsg = () => {

  const currentUser = useCurrentUser();

  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="welcome-msg-wrapper custom-padding pb-0">
              <div className="greet-emp-name">
                <h3 className="m-0 text-capitalize">Hi {currentUser?.name}!</h3>
              </div>
              <div className="personal-msg">
                <p className="m-0">
                  {/* Happy Birthday <span className="text-capitalize">{currentUser?.name}</span>, from the entire team Uniworld! */}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomeMsg;
