import { Col, Container, Row } from "react-bootstrap";
import "./Services.css";
import HeaderNav from "../sidebar/HeaderNav";
import AnnouncementBar from "../../../common-components/announcement-bar/AnnouncementBar";
import { Link, useParams } from "react-router-dom";
import CommonButton from "../../../common-components/button/CommonButton";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";

const Services = () => {

  const { id: clientId } = useParams();
  const state = useSelector((state) => state);
  const currentClient = useSelector((state) =>
    state.client?.clients?.find((client) => client._id === clientId)
  );

  console.log("current client from service page ==>", currentClient)

  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            {/* static breadcrumb  */}
            <Breadcrumb mainTitle="All Services" />

            {/* service buttons  */}
            <section className="services-cards-wrapper custom-padding">
              <Container>
                <Row>
                  {/* web & IT  */}
                  <Col lg={3}>
                    {
                      currentClient?.clientServices?.includes('Website Development') ? (
                        <Link to="#">
                          <CommonButton
                            btnClass={`wit-btn service-btn ${currentClient?.clientServices?.includes('Website Development') ? ' ' :
                              'disabled'}`}
                            btnName="Web & IT"
                          />
                        </Link>
                      ) : (
                        <CommonButton
                          btnClass={`wit-btn service-btn ${currentClient?.clientServices?.includes('Website Development') ? ' ' :
                            'disabled'}`}
                          btnName="Web & IT"
                        />
                      )
                    }

                  </Col>

                  {/* Performance marketing  */}
                  <Col lg={3}>
                    {
                      currentClient?.clientServices?.includes('Performance Marketing') ? (
                        <Link to={`${currentClient ? `/all-performance-reports/:${clientId}` : "#"}`}>
                          <CommonButton
                            btnClass={`pm-btn service-btn ${currentClient?.clientServices?.includes('Performance Marketing') ? ' ' :
                              'disabled'}`}
                            btnName="Performance Marketing"
                          />
                        </Link>
                      ) : (
                        <CommonButton
                          btnClass={`pm-btn service-btn ${currentClient?.clientServices?.includes('Performance Marketing') ? ' ' :
                            'disabled'}`}
                          btnName="Performance Marketing"
                        />
                      )
                    }

                  </Col>

                  {/* Brand Marketing  */}
                  <Col lg={3}>
                    {
                      currentClient?.clientServices?.includes('Brand Marketing') ? (
                        <Link to={`/all-reports/${clientId}`}>
                          <CommonButton
                            btnClass={`bm-btn service-btn ${currentClient?.clientServices?.includes('Brand Marketing') ? ' ' :
                              'disabled'}`}
                            btnName="Brand Marketing"
                          />
                        </Link>
                      ) : (
                        <CommonButton
                          btnClass={`bm-btn service-btn ${currentClient?.clientServices?.includes('Brand Marketing') ? ' ' :
                            'disabled'}`}
                          btnName="Brand Marketing"
                        />
                      )
                    }

                  </Col>

                  {/* Graphics  */}
                  <Col lg={3}>
                    {
                      currentClient?.clientServices?.includes('Graphics') ? (
                        <Link to="#">
                          <CommonButton
                            btnClass={`graphics-btn service-btn ${currentClient?.clientServices?.includes('Graphics') ? ' ' :
                              'disabled'}`}
                            btnName="Graphics"
                          />
                        </Link>
                      ) : (
                        <CommonButton
                          btnClass={`graphics-btn service-btn ${currentClient?.clientServices?.includes('Graphics') ? ' ' :
                            'disabled'}`}
                          btnName="Graphics"
                        />
                      )
                    }

                  </Col>
                </Row>
              </Container>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Services;
