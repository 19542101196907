import { Card, Col, Container, Row } from "react-bootstrap";
import "./MeetingCard.css";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import CommonButton from "../../button/CommonButton";
import { Link } from "react-router-dom";

const MeetingCard = ({ meetings }) => {
  const setBgColorOfElements = (status, elementType) => {
    const lowerCaseStatus = status.toLowerCase();
    const colorMap = {
      upcoming: {
        titleWrapper: "var(--violet)",
        meetingDateTimeBg: "var(--LViolet)",
        mdtBorder: "var(--violet)",
        meetingCBtn: "var(--violet)",
        meetingDTText: "var(--violet)",
      },

      today: {
        titleWrapper: "var(--Green)",
        meetingDateTimeBg: "var(--lGreen)",
        mdtBorder: "var(--Green)",
        meetingCBtn: "var(--Green)",
        meetingDTText: "var(--Green)",
      },
      // Default colors if the status is unknown
      default: {
        titleWrapper: "transparent",
        meetingDateTimeBg: "transparent",
        mdtBorder: "transparent",
        meetingCBtn: "transparent",
        meetingDTText: "transparent",
      },
    };
    return (
      colorMap[lowerCaseStatus]?.[elementType] || colorMap.default[elementType]
    );
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case "upcoming":
        return "var(--violet)";

      case "today":
        return "var(--Green)";
    }
  };
  return (
    <div className="meeting-card-wrapper custom-padding">
      <Container>
        <Row>
          {meetings.map((meeting, index) => {
            const titleBgColor = setBgColorOfElements(
              meeting.status,
              "titleWrapper"
            );

            const mdtBg = setBgColorOfElements(
              meeting.status,
              "meetingDateTimeBg"
            );

            const mdtBorder = setBgColorOfElements(meeting.status, "mdtBorder");

            const umBtn = setBgColorOfElements(meeting.status, "meetingCBtn");

            const mdtText = setBgColorOfElements(
              meeting.status,
              "meetingDTText"
            );
            return (
              <Col lg={4}>
                {/* Card */}
                <Card className="meeting-card">
                  {/* title block  */}
                  <div
                    className="mc-title-options-wrapper"
                    style={{
                      backgroundColor: titleBgColor,
                    }}
                  >
                    <div className="mc-title">
                      <h5 className="m-0">{meeting.status}</h5>
                    </div>
                    <div className="mc-options">
                      <PiDotsThreeOutlineVerticalFill />
                    </div>
                  </div>

                  {/* meeting block  */}
                  <div className="meeting-details-wrapper">
                    <div className="meeting">
                      {/* about meeting  */}
                      <div className="mc-client-name">
                        <h6>Client: {meeting.clientName}</h6>
                      </div>

                      {/* Project name  */}
                      <div className="mc-project-name">
                        <h6>Project: {meeting.projectName}</h6>
                      </div>

                      {/* meeting date time  */}
                      <div
                        className="meeting-date-time"
                        style={{
                          backgroundColor: mdtBg,
                          border: "unset",
                        }}
                      >
                        <p className="m-0" style={{ color: mdtText }}>
                          {meeting.dateTime}
                        </p>
                      </div>

                      {/* button to join or view meeting agenda */}
                      <div className="meeting-catd-btn">
                        <button
                          className="mc-btn"
                          style={{
                            backgroundColor: getStatusBgColor(meeting.status),
                          }}
                        >
                          {meeting.status === "upcoming"
                            ? "View Agenda"
                            : "Join Now"}
                        </button>
                      </div>

                      {/* cancel or not attending btn  */}
                      <div className="cancel-na-meeting-btns-wrapper">
                        <div className="cancel-btn">
                          <Link to="#">Cancel</Link>
                        </div>

                        <div className="not-attending-btn">
                          <Link to="#">Not Attending</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default MeetingCard;
