import { Col, Container, Row } from "react-bootstrap";
import "./AllReports.css";
import { FaChevronRight } from "react-icons/fa";
import HeaderNav from "../../emp-dashboard/sidebar/HeaderNav";
import AnnouncementBar from "../../../common-components/announcement-bar/AnnouncementBar";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";

const AllReports = ({
  reportUrl = "/social-media-report",
  calenderUrl = "/sm-calender",
}) => {

  const { id: clientId } = useParams();

  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            <AnnouncementBar />

            {/* breadcrumb  */}
            <Breadcrumb mainTitle="All Reoprts" />

            <section className="reports-wrapper custom-padding">
              <Container>
                <Row>
                  <Col lg={3}>
                    {/* weekly report card */}
                    <Link to={`/social-media-report/${clientId}`}>
                      <div className="report-card-wrapper weekly-report-card-wrapper">
                        <p>Reports</p>
                        <FaChevronRight />
                      </div>
                    </Link>
                  </Col>

                  <Col lg={3}>
                    {/* Calender */}
                    <Link to={`/sm-calender`}>
                    {/* <Link to={`sm-calender/${clientId}`}> */}
                      <div className="report-card-wrapper weekly-report-card-wrapper">
                        <p>Calender</p>
                        <FaChevronRight />
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AllReports;
