import { createSlice } from "@reduxjs/toolkit";

const clientReducer = createSlice({
    name: 'employee',
    initialState: {
        employees: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getEmployeesStart: (state) => {
            state.isFetching = true;
        },
        getEmployeesSuccess: (state, action) => {
            state.isFetching = false;
            state.employees = action.payload;
        },
        getEmployeesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addEmployeeStart: (state) => {
            state.isFetching = true;
        },

        addEmployeeSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.employees = [...state.employees, newUser];
        },

        addEmployeeFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteEmployeeStart: (state) => {
            state.isFetching = true;
        },

        deleteEmployeeSuccess: (state, action) => {
            state.isFetching = false;
            state.employees.splice(
                state.employees.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteEmployeeFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update user actions
        updateEmployeeStart: (state) => {
            state.isFetching = true;
        },

        updateEmployeeSuccess: (state, action) => {
            state.isFetching = false;
            const adminIndex = state.employees.findIndex(admin => admin._id === action.payload._id);
            if (adminIndex !== -1) {
                state.employees[adminIndex] = action.payload;
            }
        },

        updateEmployeeFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getEmployeesStart, getEmployeesSuccess, getEmployeesFailure, deleteEmployeeStart, deleteEmployeeSuccess, deleteEmployeeFailure, addEmployeeFailure, addEmployeeStart, addEmployeeSuccess, updateEmployeeStart, updateEmployeeSuccess, updateEmployeeFailure } = clientReducer.actions;
export default clientReducer.reducer;

