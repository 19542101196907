import "./Attendance.css";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import GeneralTable from "../../../common-components/gen-table/GeneralTable";
import { useEffect, useState } from "react";
import { userRequest } from "../../../requestMethod";
import { changeDateFormat } from "../../../config";

const Attendance = () => {
  const [attendanceRecords, setAttendanceRecords] = useState([]);

  const attendanceTableHeader = [
    {
      headerName: "Date",
      valueToBeRender: "dateOfTheDay",
      renderFunction: (params) => {
        return changeDateFormat(params.value);
      },
    },
    {
      headerName: "Day",
      valueToBeRender: "nameOfTheDay",
    },
    {
      headerName: "In Time",
      valueToBeRender: "logInTime",
    },
    {
      headerName: "Out Time",
      valueToBeRender: "logOffTime",
    },
  ];
  // const attendanceTableHeader = [
  //   "S. No.",
  //   "Date",
  //   "Status",
  //   "In Time",
  //   "Out Time",
  // ];

  // const attendanceRecords = [
  //   {
  //     "S. No.": 1,
  //     Date: "2024-07-01",
  //     Status: "Present",
  //     "In Time": "09:00 AM",
  //     "Out Time": "05:00 PM",
  //   },
  //   {
  //     "S. No.": 2,
  //     Date: "2024-07-02",
  //     Status: "Absent",
  //     "In Time": "-",
  //     "Out Time": "-",
  //   },
  //   {
  //     "S. No.": 3,
  //     Date: "2024-07-03",
  //     Status: "Present",
  //     "In Time": "09:15 AM",
  //     "Out Time": "05:10 PM",
  //   },
  //   {
  //     "S. No.": 4,
  //     Date: "2024-07-04",
  //     Status: "Present",
  //     "In Time": "08:50 AM",
  //     "Out Time": "05:00 PM",
  //   },
  //   {
  //     "S. No.": 5,
  //     Date: "2024-07-05",
  //     Status: "Present",
  //     "In Time": "09:05 AM",
  //     "Out Time": "05:05 PM",
  //   },
  //   {
  //     "S. No.": 6,
  //     Date: "2024-07-06",
  //     Status: "Absent",
  //     "In Time": "-",
  //     "Out Time": "-",
  //   },
  //   {
  //     "S. No.": 7,
  //     Date: "2024-07-07",
  //     Status: "Present",
  //     "In Time": "09:00 AM",
  //     "Out Time": "05:00 PM",
  //   },
  //   {
  //     "S. No.": 8,
  //     Date: "2024-07-08",
  //     Status: "Present",
  //     "In Time": "09:10 AM",
  //     "Out Time": "05:15 PM",
  //   },
  //   {
  //     "S. No.": 9,
  //     Date: "2024-07-09",
  //     Status: "Absent",
  //     "In Time": "-",
  //     "Out Time": "-",
  //   },
  //   {
  //     "S. No.": 10,
  //     Date: "2024-07-10",
  //     Status: "Present",
  //     "In Time": "08:55 AM",
  //     "Out Time": "05:00 PM",
  //   },
  // ];

  useEffect(() => {
    const checkWorkStatus = async () => {
      try {
        const res = await userRequest.get("/attendance/all");
        console.log("getting response in attendance all ==>", res);
        if (res.status === 200) {
          // setIsWorkStart(res.data.isWorkStart);
          setAttendanceRecords(res.data);
        }
      } catch (error) {
        console.log("Error checking work status", error);
      }
    };
    checkWorkStatus();
  }, []);

  return (
    <>
      <Breadcrumb mainTitle="Attendance" />

      <div className="attendance-record-table custom-padding">
        <GeneralTable
          headers={attendanceTableHeader}
          tableData={attendanceRecords}
          // smData={attendanceRecords}
        />
      </div>
    </>
  );
};

export default Attendance;
