import { createSlice } from "@reduxjs/toolkit";

const leaveReducer = createSlice({
    name: 'leave',
    initialState: {
        leaves: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getLeavesStart: (state) => {
            state.isFetching = true;
        },
        getLeavesSuccess: (state, action) => {
            state.isFetching = false;
            state.leaves = action.payload;
        },
        getLeavesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addLeaveStart: (state) => {
            state.isFetching = true;
        },

        addLeaveSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.leaves = [...state.leaves, newUser];
        },

        addLeaveFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteLeaveStart: (state) => {
            state.isFetching = true;
        },

        deleteLeaveSuccess: (state, action) => {
            state.isFetching = false;
            state.leaves.splice(
                state.leaves.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteLeaveFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // // Update user actions
        // updateLeaveStart: (state) => {
        //     state.isFetching = true;
        // },

        // updateLeaveSuccess: (state, action) => {
        //     state.isFetching = false;
        //     const adminIndex = state.leaves.findIndex(admin => admin._id === action.payload._id);
        //     if (adminIndex !== -1) {
        //         state.leaves[adminIndex] = action.payload;
        //     }
        // },

        // updateLeaveFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },
    },
});

export const { getLeavesStart, getLeavesSuccess, getLeavesFailure, deleteLeaveStart, deleteLeaveSuccess, deleteLeaveFailure, addLeaveFailure, addLeaveStart, addLeaveSuccess, updateLeaveStart, updateLeaveSuccess, updateLeaveFailure } = leaveReducer.actions;
export default leaveReducer.reducer;

