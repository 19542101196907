import React from 'react'
import DynamicForm from '../../../../common-components/form/DynamicForm'

const AddSMReport = () => {

    const smReportSchema = [
        {
            name: "project",
            type: "text",
            label: "Project",
        }
    ]

    return (
        <DynamicForm />
    )
}

export default AddSMReport
