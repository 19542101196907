import { Col, Container, Row } from "react-bootstrap";
import "./Departments.css";
import OverviewCards from "../../../common-components/cards/overview-cards/OverviewCards";
import { useEffect, useState } from "react";
import EmployeeCard from "../../../common-components/cards/employee-card/EmployeeCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  getAllProjects,
  getAllUsers,
} from "../../../redux/apiCalls";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";

const Departments = () => {
  // Define departments only once
  const departments = [
    {
      content: "Web & IT",
      bgColor: "var(--Green)",
      customClass: "dept-card",
    },
    {
      content: "Graphic Department",
      bgColor: "var(--LSkyBlue)",
      customClass: "dept-card",
    },
    {
      content: "Performance Marketing",
      bgColor: "var(--Orange)",
      customClass: "dept-card",
    },
    {
      content: "Brand Marketing",
      bgColor: "var(--Red)",
      customClass: "dept-card",
    },
    {
      content: "Content Department",
      bgColor: "var(--Turquoise)",
      customClass: "dept-card",
    },
    {
      content: "Accounts",
      bgColor: "var(--Pink)",
      customClass: "dept-card",
    },
  ];

  // Department data
  const departmentData = [
    {
      deptName: "Web & IT",
      deptHead: "Kritika Dwivedi",
      teamCount: 5,
      clientCount: 25,
      projectCount: 30,
    },
    {
      deptName: "Graphic Department",
      deptHead: "Not Assigned",
      teamCount: 3,
      clientCount: 25,
      projectCount: 30,
    },
    {
      deptName: "Performance Marketing",
      deptHead: "Akshara Singh",
      teamCount: 3,
      clientCount: 25,
      projectCount: 30,
    },
    {
      deptName: "Brand Marketing",
      deptHead: "Akshara Singh",
      teamCount: 2,
      clientCount: 25,
      projectCount: 30,
    },
    {
      deptName: "Content Department",
      deptHead: "Yash",
      teamCount: 1,
      clientCount: 25,
      projectCount: 30,
    },
    {
      deptName: "Accounts",
      deptHead: "Vidya Sagar",
      teamCount: 1,
      clientCount: 25,
      projectCount: 30,
    },
  ];

  // UseState with initial value from departments
  const [selectedDept, setSelectedDept] = useState(departments[0].content);
  const [selectedDeptColor, setSelectedDeptColor] = useState(
    departments[0].bgColor
  );

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  // Rest of your code remains unchanged...

  useEffect(() => {
    const organisationId = currentUser?.isUserOrganisationOwner
      ? currentUser._id
      : currentUser?.organisationId;
    getAllClients(dispatch, organisationId);
  }, [dispatch]);

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  const { isFetching: isProjectsFetching, projects: allProjects } = useSelector(
    (state) => state.projects
  );

  const { isFetching, clients: allClients } = useSelector(
    (state) => state?.client
  );

  const nameMapping = {
    "Web & IT": "Web Department",
  };

  const handleDeptCardClick = (deptName, color) => {
    console.log("selected department click ==>", deptName);
    const normalizedDeptName =
      deptName === "Web & IT" ? "Web Department" : deptName;
    setSelectedDept(deptName);
    setSelectedDeptColor(color);
  };

  const setSelectedDeptData =
    departmentData.find((dept) => dept.deptName === selectedDept) || null;

  const state = useSelector((state) => state);
  const allEmployees = useSelector((state) => state?.allUsers?.users);
  console.log("all employees  data 0000000 ==>", allEmployees);

  useEffect(() => {
    getAllUsers(dispatch);
  }, [dispatch]);

  const filteredEmployees =
    allEmployees?.filter(
      (employee) =>
        employee.department === (nameMapping[selectedDept] || selectedDept)
    ) || [];

  console.log(
    "all filtered employees in departments page ==>",
    filteredEmployees
  );

  return (
    <div className="departments-container">
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Teams" />

      {/* department cards  */}
      <section className="department-cards custom-padding">
        <Container>
          <Row>
            {departments.map((dept, index) => (
              <Col
                key={index}
                lg={3}
                md={6}
                sm={6}
                className="deptCardCol"
                onClick={() => handleDeptCardClick(dept.content, dept.bgColor)}
              >
                <OverviewCards
                  OvCardContent={dept.content}
                  bgColor={dept.bgColor}
                  customClass={dept.customClass}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* department overview */}
      {selectedDept && (
        <>
          <section
            className="department-overview custom-padding"
            style={{ backgroundColor: selectedDeptColor }}
          >
            <Container>
              <Row className="align-items-center">
                <Col lg={7} md={7} sm={12}>
                  <div className="dept-name-wrapper">
                    <h4>{setSelectedDeptData?.deptName || "Select a team"}</h4>
                  </div>
                </Col>
                <Col lg={5} md={5} sm={12}>
                  <div className="dept-details-container">
                    {/* department head  */}
                    <div className="dept-detail">
                      <p>Manager</p>
                      <p>
                        {setSelectedDeptData?.deptHead ||
                          "Manager not assigned"}
                      </p>
                    </div>

                    {/* department team count  */}
                    <div className="dept-detail">
                      <p>Team Members</p>
                      <p>{filteredEmployees?.length}</p>
                    </div>

                    {/* department client count  */}
                    <div className="dept-detail">
                      <p>Clients</p>
                      <p>{allClients?.length}</p>
                    </div>

                    {/* deparments projects count  */}
                    <div className="dept-detail">
                      <p>Projects</p>
                      <p>{allProjects?.length}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="department-employees custom-padding">
            <EmployeeCard details={filteredEmployees} />
          </section>
        </>
      )}
    </div>
  );
};


export default Departments;