import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import "./ManageProject.css";
import "../../../../common-components/form/DynamicForm.css";
import NewTaskTable from "../../../../common-components/new-task-table/NewTaskTable";
import CommonButton from "../../../../common-components/button/CommonButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectFailure,
  updateProjectStart,
  updateProjectSuccess,
} from "../../../../redux/projectRedux";
import { FaTrash } from "react-icons/fa";
import { deepEqualCheckForTwoArray } from "../../../../config";
import Popup from "../../../../common-components/popup/Popup";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import { userRequest } from "../../../../requestMethod";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { getAllProjects } from "../../../../redux/apiCalls";

const ManageProject = () => {
  const { id: projectId } = useParams();

  // const dispatch = useDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  const currentProject = useSelector((state) =>
    state.projects?.projects?.find((project) => project._id === projectId)
  );

  const isProjectFetching = useSelector((state) => state.projects.isFetching);

  const allProjects = useSelector((state) => state.projects?.projects);

  console.log("all projects ==>", allProjects);




  const [tasks, setTasks] = useState(currentProject?.tasks || []);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editedTask, setEditedTask] = useState({
    summary: "",
    endDate: "",
    status: "",
    progress: 0,
  });
  const [isEligibleForSave, setIsEligibleForSave] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Tasks has been updated.");

  useEffect(() => {
    // setTasks(currentProject.tasks || []);
    // currentProject?.tasks === tasks ? setIsEligibleForSave(false) : setIsEligibleForSave(true)
    const areTasksEqual = deepEqualCheckForTwoArray(
      currentProject?.tasks,
      tasks
    );
    setIsEligibleForSave(!areTasksEqual);
  }, [currentProject?.tasks, tasks]);

  useEffect(() => {
    setTasks(currentProject?.tasks);
  }, [currentProject?.tasks]);

  const handleAddTask = () => {
    const newTasks = [...tasks];

    newTasks.push({
      _id: null, // null indicates it's a new task
      summary: "",
      endDate: "",
      priority: "",
      status: "",
      progress: 0,
      isOnEditMode: true,
      addedBy: "You",
    });
    setTasks(newTasks);
  };

  const handleEditClick = (task) => {
    const updatedTasks = tasks?.map((t) =>
      t._id === task._id ? { ...t, isOnEditMode: true } : t
    );
    setTasks(updatedTasks);
  };

  // const handleDeleteClick = (taskIndex) => {
  //   // const updatedTasks = tasks?.filter((_, index) => index !== taskIndex);
  //   const updatedTasks = tasks?.filter((task) => task._id !== taskId);

  //   setTasks(updatedTasks);
  // };

  // const handleDeleteClick = (taskIndex) => {
  //   const tasksCopy = [...tasks];
  //   if (tasksCopy.length > 0) {
  //     tasksCopy.splice(taskIndex, 1);
  //     setTasks(tasksCopy);
  //   }
  // };

  const handleDeleteClick = (taskIndex) => {
    const tasksCopy = [...tasks];
    if (tasksCopy.length > 0) {
      tasksCopy[taskIndex] = {
        ...tasksCopy[taskIndex],
        isDeleted: true,
      };
      setTasks(tasksCopy);
    }
  };

  const handleInputChange = (e, taskIndex) => {
    const { name, value } = e.target;
    const updatedTasks = tasks?.map((task, index) =>
      index === taskIndex ? { ...task, [name]: value } : task
    );
    setTasks(updatedTasks);
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate(); // useHistory hook to programmatically navigate

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // Handle navigation to selected project
  const handleSelectProject = (projectId) => {
    // Navigate to the selected project's page
    navigate(`/manage-project/${projectId}`, { replace: true });
    setDropdownVisible(false); // Close the dropdown after selection
  };

  const handleSaveChanges = async (finalData) => {
    setIsLoaderActive(true);
    try {
      dispatch(updateProjectStart());
      const res = await userRequest.put(
        `/project/add-task/${projectId}`,
        finalData
      );
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText("Tasks have been updated");
        // updateProjectSuccess
        dispatch(updateProjectSuccess(res.data));
        // setValues(schema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}))
      }
      console.log("currentProject after save changes fun ==>", currentProject);
    } catch (error) {
      dispatch(updateProjectFailure());
    }
  };

  const handleSaveTasks = () => {
    // handleSaveChanges(tasks)
    // const modifiedTasks = tasks?.filter(task => task?.isOnEditMode);
    handleSaveChanges(tasks);
    // handleSaveChanges(modifiedTasks);
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  if (isProjectFetching) {
    return <MiniLoader />;
  }

  return (
    <>
      <div className="breadcrumb-pg-header-container">
        <Breadcrumb
          mainTitle="Manage Project"
          customClass="breadcrumb-custom"
        />

        <div className="mp-project-name-wrapper custom-padding">
          <Container>
            <Row className="align-items-center">
              {/*<Col lg={12} md={12} sm={12} className="p-0">*/}
              {/* <h3 className="mp-project-name-label"></h3> */}
              <div className="col-lg-6">
                <div className="manage-project-dropdown">
                  <button className="btn-edit" onClick={handleToggleDropdown}>
                    {currentProject?.title}
                    {isDropdownVisible ? (
                      <FaAngleUp className="m-0" />
                    ) : (
                      <FaAngleDown className="m-0" />
                    )}
                  </button>
                  {isDropdownVisible && (
                    <ul className="dropdown-list">
                      {allProjects?.map((project, index) => (
                        <li
                          key={index}
                          className="dropdown-item"
                          onClick={() => handleSelectProject(project?._id)}
                        >
                          {project?.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {/* <div className="manage-project-dropdown">
                    <CommonButton
                      btnName={currentProject?.title}
                      btnClass="btn-edit"
                      onClickAction={handleToggleDropdown}
                    />
                  </div> */}

              {/* Dropdown List */}

              {/* </select> */}
              <div className="col-lg-6 text-align-last">
                <div>
                  <CommonButton
                    btnName="Add Task"
                    btnClass="btn-edit mr-10"
                    onClickAction={handleAddTask}
                  />
                  {isEligibleForSave ? (
                    <CommonButton
                      btnName="Save Changes"
                      btnClass="btn-edit"
                      onClickAction={handleSaveTasks}
                    />
                  ) : (
                    <CommonButton
                      btnName="Save Changes"
                      btnClass="btn-edit not-clickable"
                    />
                  )}
                </div>
              </div>
              {/* </Col> */}
            </Row>
          </Container>
        </div>
      </div>

      <div className="project-tasks-management custom-padding">
        {/* new task table  */}
        <NewTaskTable
          tasks={tasks}
          handleInputChange={handleInputChange}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
          customTtClass="customTt"
          currentProject={currentProject?.title}
        />
        {/* <div>
          <table className="tasks-table nt-table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Task Summary</th>
                <th>Task Deadline</th>
                <th>Task Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks?.map((task, index) => (
                <tr key={task.id}>
                  <td>{index + 1}</td>
                  <td>
                    {task?.isOnEditMode ? (
                      <input
                        type="text"
                        name="summary"
                        value={task.summary}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    ) : (
                      task?.summary
                    )}
                  </td>
                  <td>
                    {task?.isOnEditMode ? (
                      <input
                        type="date"
                        name="endDate"
                        value={task.endDate}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    ) : (
                      task?.endDate
                    )}
                  </td>
                  <td>
                    {task?.isOnEditMode ? (
                      <select
                        name="status"
                        value={task.status}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option selected disabled >Select status</option>
                        <option value="completed">Completed</option>
                        <option value="inProgress">In Progress</option>
                        <option value="onHold">On Hold</option>
                      </select>
                    ) : (
                      task?.status
                    )}
                  </td>
                  <td>
                    <FaTrash
                      onClick={() => handleDeleteClick(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
      )}
    </>
  );
};

export default ManageProject;
