import { Col, Container, Row } from "react-bootstrap";
import "../../../../styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import SocialMediaPlanForm from "../social-media-plan-form/SocialMediaPlanForm";
import CommonButton from "../../../../common-components/button/CommonButton";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import { userCalenderSchema } from "../../../../formSchema";
import { changeDateFormat } from "../../../../config";

const UserCalendar = () => {
  const [date, setDate] = useState(new Date());
  const [plans, setPlans] = useState({});
  const [formData, setFormData] = useState({
    brand: "",
    contentBucket: "",
    intent: "",
    description: "",
    references: "",
    approvedReferences: "",
    typeOfPost: "",
    targetAudience: "",
    hookForAudience: "",
    socialMediaPlatform: "",
    tagline: "",
    caption: "",
    hashtags: "",
    creative: "",
  });

  const [showAddPlanButton, setShowAddPlanButton] = useState(false);
  const [showPlanForm, setShowPlanForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [planIndex, setPlanIndex] = useState(null);

  const minDate = new Date("2023-01-01");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add a plan with all the detailed information or update an existing plan
  const addPlan = () => {
    const formattedDate = date.toISOString().split("T")[0]; // Date in YYYY-MM-DD format
    const updatedPlans = { ...plans };

    if (!updatedPlans[formattedDate]) {
      updatedPlans[formattedDate] = [];
    }

    // Check if in edit mode and update plan instead of adding a new one
    if (editMode && planIndex !== null) {
      // Update the existing plan at the specified index
      updatedPlans[formattedDate][planIndex] = formData;
    } else {
      // Add a new plan
      updatedPlans[formattedDate].push(formData);
    }

    // Update the plan state
    setPlans(updatedPlans);

    // Reset form data and edit state
    setFormData({
      brand: "",
      contentBucket: "",
      intent: "",
      description: "",
      references: "",
      approvedReferences: "",
      typeOfPost: "",
      targetAudience: "",
      hookForAudience: "",
      socialMediaPlatform: "",
      tagline: "",
      caption: "",
      hashtags: "",
      creative: "",
    });

    // Reset edit mode and plan index
    setEditMode(false);
    setPlanIndex(null);

    // Hide the plan form
    setShowPlanForm(false);
  };

  // Handle date selection and toggle "Add Plan" button visibility
  const handleDateClick = (selectedDate) => {
    console.log("clicked date with variable ==>", changeDateFormat(date));
    setDate(selectedDate);
    setShowAddPlanButton(true);
    setShowPlanForm(false);
  };

  // Handle plan edit action
  const handleEditPlan = (index, plan) => {
    setPlanIndex(index);
    setFormData(plan);
    setEditMode(true);
    setShowPlanForm(true);
  };

  // Render the plan information for a selected date
  const renderPlanData = () => {
    const formattedDate = date.toISOString().split("T")[0];
    const plansForDate = plans[formattedDate] || [];

    // A helper function to render multiple links in a single field
    const renderLinks = (text) => {
      const links = text.split(/,|;|\s/).filter((link) => link); // Split text by commas, semicolons, or spaces and filter out empty strings
      return (
        <div>
          {links.map((link, idx) => (
            <div key={idx}>
              {link.startsWith("http") ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              ) : (
                link
              )}
            </div>
          ))}
        </div>
      );
    };

    return (
      <div className="plans-data-wrapper">
        {plansForDate.map((plan, index) => (
          <div key={index} className="plan-data-edit-btn-container">
            <div className="plan-data">
              <h4>Plan {index + 1}:</h4>
              <ol className="plan-list">
                {Object.keys(plan).map((key, idx) => {
                  const isReferenceField =
                    key === "references" || key === "approvedReferences";

                  return (
                    <li key={idx}>
                      <strong>
                        {key.replace(/([A-Z])/g, " $1").trim()}:&nbsp;
                      </strong>
                      {isReferenceField
                        ? // Render multiple links using the renderLinks function
                        renderLinks(plan[key])
                        : plan[key]}
                    </li>
                  );
                })}
              </ol>
            </div>
            {/* Add edit button */}
            <div className="plan-edit-button">
              <CommonButton
                onClickAction={() => handleEditPlan(index, plan)}
                btnClass="common-btn"
                btnName="Edit"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Define the function to render content on each tile (day) of the calendar
  const renderTileContent = ({ date, view }) => {
    // We only want to render content for the month view
    if (view === "month") {
      // Format the date to a string (YYYY-MM-DD)
      const formattedDate = date.toISOString().split("T")[0];
      // Retrieve the plans for this date
      const plansForDate = plans[formattedDate] || [];

      // Extract the brand names from the plans
      const brandNames = plansForDate.map((plan) => plan.brand);

      // If there are brand names, render them
      if (brandNames.length > 0) {
        return (
          <div className="calendar-tile-content">
            {/* Render each brand name on its own line */}
            {brandNames.map((brand, index) => (
              <div key={index} className="plan-brand-name">
                <p>{brand}</p>
              </div>
            ))}
          </div>
        );
      }
    }

    // If there are no plans or if the view is not month, return null
    return null;
  };

  return (
    <div className="custom-padding">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="calender-wrapper">
              {/* Calendar component */}
              <Calendar
                onClickDay={handleDateClick}
                value={date}
                minDate={minDate}
                tileContent={renderTileContent}
              />

              <div className="add-plan-btn">
                {showAddPlanButton && (
                  <CommonButton
                    onClickAction={() => setShowPlanForm(true)}
                    btnClass="common-btn"
                    btnName="Add Event"
                  />
                )}
              </div>

              {showPlanForm && (
                // <SocialMediaPlanForm
                //   formData={formData}
                //   handleChange={handleChange}
                //   addPlan={addPlan}
                //   editMode={editMode}           
                //   setEditMode={setEditMode}     
                //   planIndex={planIndex}        
                // />
                <DynamicForm schema={userCalenderSchema} httpMethod={"post"} apiEndPoint={'/calendar/create'} />
              )}

              <div className="taskhead-content">
                <h3>Plan for {date.toDateString()}:</h3>
                <div className="plan-content pb-50">{renderPlanData()}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserCalendar;
