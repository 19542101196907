import { Container, Row } from "react-bootstrap";
import "./GeneralTable.css";

// const GeneralTable = ({ rows, columns }) => {
//   const renderCellContent = (value, isComparisonRow) => {
//     if (
//       isComparisonRow &&
//       value &&
//       typeof value === "object" &&
//       "value" in value &&
//       "isPositive" in value
//     ) {
//       return (
//         <span
//           style={{ color: value.isPositive ? "var(--Green)" : "var(--Red)" }}
//         >
//           {value.value}
//         </span>
//       );
//     }
//     return value;
//   };
//   return (
//     <section className="sm-kpi-table-wrapper">
//       <Container>
//         <Row>
//           <table className="kpi-table">
//             <thead>
//               <tr>
//                 {columns?.map((column, cIndex) => (
//                   <th key={cIndex}>{column?.header}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {rows?.map((row, rowIndex) => (
//                 // console.log("row ==>", row)
//                 <tr key={rowIndex}>
//                   {/* {Object.keys(row).map((property, index) => (
//                     <td key={index}>
//                       {
//                       renderCellContent(
//                         row[property],
//                         rowIndex === columns.length - 1
//                       )
//                       }
//                     </td>
//                   ))} */}
//                   {columns?.map((column, colIndex) => (
//                     <td key={colIndex}>
//                       {renderCellContent(
//                         row[column.field],
//                         rowIndex === columns.length - 1
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Row>
//       </Container>
//     </section>
//   );
// };
// export default GeneralTable;
const GeneralTable = ({ headers, tableData }) => {
  return (
    <section className="sm-kpi-table-wrapper">
      <Container>
        <Row>
          <table className="kpi-table">
            <thead>
              <tr>
                <th>#</th> {/* Serial number header */}
                {headers?.map((header, hIndex) => (
                  <th key={hIndex}>{header?.headerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{rowIndex + 1}</td> {/* Serial number column */}
                  {headers?.map((header, cellIndex) => {
                    const cellValue = rowData[header.valueToBeRender] || "-"; // Fallback value
                    return (
                      <td key={cellIndex}>
                        {/* Check if renderFunction exists, else render the plain value */}
                        {header.renderFunction
                          ? header.renderFunction({ value: cellValue, rowData })
                          : cellValue}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </Container>
    </section>
  );
};



// ==================old method to render table's row and cell =====================
// const GeneralTable = ({ headers, smData }) => {
//   const renderCellContent = (value, isComparisonRow) => {
//     if (
//       isComparisonRow &&
//       value &&
//       typeof value === "object" &&
//       "value" in value &&
//       "isPositive" in value
//     ) {
//       return (
//         <span
//           style={{ color: value.isPositive ? "var(--Green)" : "var(--Red)" }}
//         >
//           {value.value}
//         </span>
//       );
//     }
//     return value;
//   };
//   return (
//     <section className="sm-kpi-table-wrapper">
//       <Container>
//         <Row>
//           <table className="kpi-table">
//             <thead>
//               <tr>
//                 {headers?.map((header, hIndex) => (
//                   <th key={hIndex}>{header}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {smData?.map((cell, cellIndex) => (
//                 <tr key={cellIndex}>
//                   {Object.keys(cell).map((property, index) => (
//                     <td key={index}>
//                       {renderCellContent(
//                         cell[property],
//                         cellIndex === smData.length - 1
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Row>
//       </Container>
//     </section>
//   );
// };
export default GeneralTable;
