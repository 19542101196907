import { Col, Container, Row } from "react-bootstrap";
import "./Employees.css";
import AnnouncementBar from "../../../common-components/announcement-bar/AnnouncementBar";
import HeaderNav from "../sidebar/HeaderNav";
import EmployeeCard from "../../../common-components/cards/employee-card/EmployeeCard";
import CommonButton from "../../../common-components/button/CommonButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllEmployees, getAllUsers } from "../../../redux/apiCalls";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
const Employees = () => {
  const empData = [
    {
      employeeNo: 12345,
      empImg: "user-img.png",
      name: "Raunak Singh",
      team: "Web & IT - UI-UX Developer",
      email: "john@store.com",
      phone: "+91 9876543210",
      rManager: "Kritika",
      rmImg: "user-img.png",
      empStatus: "full-time",
    },
    {
      employeeNo: "2468@US",
      empImg: "user-img.png",
      name: "Manan Kapoor",
      team: "Web & IT - UI-UX Designer",
      email: "john@store.com",
      phone: "+91 9876543210",
      rManager: "Kritika",
      rmImg: "user-img.png",
      empStatus: "Intern",
    },
    {
      employeeNo: "9876@US",
      empImg: "user-img.png",
      name: "Moin Khan",
      team: "Web & IT - Full Stack Developer",
      email: "john@store.com",
      phone: "+91 9876543210",
      rManager: "Kritika",
      rmImg: "user-img.png",
      empStatus: "notice Period",
    },
    {
      employeeNo: "5432@US",
      empImg: "user-img.png",
      name: "Lalan Singh",
      team: "Bussiness Development",
      email: "john@store.com",
      phone: "+91 9876543210",
      rManager: "Kritika",
      rmImg: "user-img.png",
      empStatus: "Former",
    },
  ];

  const state = useSelector((state) => state);
  const { isFetching, employees: allEmployees } = useSelector(
    (state) => state?.employee
  );

  console.log("all employees  data ==>", allEmployees);
  console.log("state from employee ==>", state);

  const currentUser = useCurrentUser();

  console.log("current user in employee from hooks", currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    const organisationId = currentUser?.isUserOrganisationOwner
      ? currentUser._id
      : currentUser?.organisationId;
    getAllEmployees(dispatch, organisationId);
  }, [dispatch]);

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <div className="employees-wrapper">
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            {/* breadcrumb */}
            <Breadcrumb mainTitle="Employees" />
            <div className="custom-padding">
              {(currentUser?.isUserUniworldAdmin ||
                currentUser?.isUserDepartmentHead) && (
                <div className="action-btn-wrapper cp-card-section pb-0">
                  <Link to={"/add-employee"}>
                    <CommonButton
                      btnName="Add Employee"
                      btnClass="btn-edit common-btn"
                    />
                  </Link>
                </div>
              )}

              {/* employee cards */}
              <EmployeeCard details={allEmployees} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Employees;
