import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./HeaderNav.css";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { MdOutlineTask, MdOutlineVideoCameraFront } from "react-icons/md";
import { GrOverview, GrProjects, GrUserSettings } from "react-icons/gr";
import { FaPeopleRoof } from "react-icons/fa6";
import { RiUserStarLine } from "react-icons/ri";
import { BsCalendar2X, BsTicketDetailed } from "react-icons/bs";
import { FiUserCheck } from "react-icons/fi";
import { TbReport } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { CgUserList, CgGitPull } from "react-icons/cg";
import { GoSignOut } from "react-icons/go";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { LuArrowLeftToLine } from "react-icons/lu";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logoutUser } from "../../../redux/userRedux";
import { persistor } from "../../../redux/store";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";

const HeaderNav = (props) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const menuIcon = () => {
    menuCollapsed ? setMenuCollapsed(false) : setMenuCollapsed(true);
  };

  const currentUser = useCurrentUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("persist:root");
    Cookies.remove("userIn");
    persistor.purge().then(() => {
      dispatch(logoutUser());
    });
    navigate("/", { replace: true });
  };

  return (
    <div className="sidebar-main-wrapper">
      <Sidebar collapsed={menuCollapsed} className="sidebar-inner-wrapper">
        <div className="sidebar-header-menu">
          <div className="Sidebar-header">
            {/* greetings and Username */}
            {!menuCollapsed && (
              <div className="greeting-name">
                <h5 className="m-0 text-capitalize">{`Welcome!`}</h5>
              </div>
            )}

            <div className="collapse-icon" onClick={menuIcon}>
              {menuCollapsed ? <AiOutlineMenuUnfold /> : <LuArrowLeftToLine />}
            </div>
          </div>

          <Menu className="custom-scrollbar custom-sidebar-db sidebar-list">
            <MenuItem
              icon={<GrOverview />}
              component={<Link to="/emp-overview" />}
            >
              Overview
            </MenuItem>
            {
              currentUser?.isUserUniworldAdmin && (
                <MenuItem
                  icon={<GrOverview />}
                  component={<Link to="/global-notification" />}
                >
                  Global Notification
                </MenuItem>
              )
            }

            <MenuItem
              icon={<CgUserList />}
              component={<Link to="/employees" />}
            >
              Employees
            </MenuItem>
            <MenuItem icon={<FaPeopleRoof />} component={<Link to="/teams" />}>
              Teams
            </MenuItem>
            <MenuItem
              icon={<RiUserStarLine />}
              component={<Link to="/clients" />}
            >
              Clients
            </MenuItem>
            <MenuItem
              icon={<GrProjects />}
              component={<Link to="/all-projects" />}
            >
              Projects
            </MenuItem>
            <MenuItem
              icon={<MdOutlineTask />}
              component={<Link to="/all-tasks" />}
            >
              Tasks
            </MenuItem>
            <MenuItem
              icon={<BsCalendar2X />}
              component={<Link to="/leaves-overview" />}
            >
              Leaves
            </MenuItem>
            <MenuItem
              icon={<FiUserCheck />}
              component={<Link to="/attendance-records" />}
            >
              Attendance
            </MenuItem>
            {/* <MenuItem icon={<TbReport />}>Reports</MenuItem> */}
            {/* <MenuItem icon={<SlCalender />}
              component={<Link to="/calender" />}
            >
              Calender
            </MenuItem>
            <MenuItem
              icon={<BsTicketDetailed />}
              component={<Link to="/tickets" />}
            >
              Tickets
            </MenuItem>
            <MenuItem
              icon={<MdOutlineVideoCameraFront />}
              component={<Link to="/all-meetings" />}
            >
              Meetings
            </MenuItem>
            <MenuItem icon={<CgGitPull />}>Referrals</MenuItem>
            <MenuItem
              icon={<GrUserSettings />}
              component={<Link to="/account-setting" />}
            >
              Account Setting
            </MenuItem> */}
            <MenuItem icon={<GoSignOut />} onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
      {/* <main className="main-container">
        <Routes>
          <Route path="/emp-overview" element={<EmpOverview />}></Route>
        </Routes>
      </main> */}
    </div>
  );
};

export default HeaderNav;
