import { createSlice } from "@reduxjs/toolkit";

const registeredUserReducer = createSlice({
    name: 'registeredUsers',
    initialState: {
        users: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getUsersStart: (state) => {
            state.isFetching = true;
        },
        getUsersSuccess: (state, action) => {
            state.isFetching = false;
            state.users = action.payload;
        },                                      
        getUsersFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addUserStart: (state) => {
            state.isFetching = true;
        },

        addUserSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.users = [...state.users, newUser];
        },

        addUserFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteUserStart: (state) => {
            state.isFetching = true;
        },

        deleteUserSuccess: (state, action) => {
            state.isFetching = false;
            state.users.splice(
                state.users.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteUserFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update user actions
        updateUserStart: (state) => {
            state.isFetching = true;
        },

        updateUserSuccess: (state, action) => {
            state.isFetching = false;
            const adminIndex = state.users.findIndex(admin => admin._id === action.payload._id);
            if (adminIndex !== -1) {
                state.users[adminIndex] = action.payload;
            }
        },

        updateUserFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.users = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getUsersStart, getUsersSuccess, getUsersFailure, deleteUserStart, deleteUserSuccess, deleteUserFailure, addUserFailure, addUserStart, addUserSuccess, updateUserStart, updateUserSuccess, updateUserFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure } = registeredUserReducer.actions;
export default registeredUserReducer.reducer;

