import React, { useState } from "react";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import { clientSchema } from "../../../../formSchema";
import { Col, Container, Form, Row } from "react-bootstrap";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import CommonButton from "../../../../common-components/button/CommonButton";
import { userRequest } from "../../../../requestMethod";
import Popup from "../../../../common-components/popup/Popup";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";

const AddClient = () => {
  // State for form values
  const [formValues, setFormValues] = useState({
    name: "",
    brandName: "",
    email: "",
    phone: "",
    clientStatus: "",
    clientServices: [], // Array to track selected services
    poc: [], // Array to store POC data for each selected service
    isUserClient: true,
  });

  console.log("form values ==>", formValues);

  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");

  const handleToggle = () => {
    setIsDropDownVisible(!isDropDownVisible);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox selections (services)
      setFormValues((prev) => ({
        ...prev,
        clientServices: checked
          ? [...prev.clientServices, name] // Add service if checked
          : prev.clientServices.filter((service) => service !== name), // Remove service if unchecked
      }));
    } else {
      // Handle other inputs
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle POC input changes
  const handlePocChange = (e, service, field, index) => {
    const { value } = e.target;

    setFormValues((prev) => {
      const updatedPoc = [...prev.poc];

      if (!updatedPoc[index]) {
        updatedPoc[index] = {
          name: "",
          contact: "",
          department: formValues?.clientServices[index],
        };
      }

      updatedPoc[index][field] = value;

      return {
        ...prev,
        poc: updatedPoc,
      };
    });
  };

  const submitData = async (data) => {
    console.log("coming data ==>", data);
    try {
      const res = await userRequest.post("/user/create", data);
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res.data);
        // setValues(schema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}))
      }
    } catch (error) {
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data);
    }
  };

  const handleSubmit = () => {
    submitData(formValues);
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Add Client" />

      <div className="add-emp-form-wrapper custom-padding">
        {/* <DynamicForm
          schema={clientSchema}
          httpMethod="post"
          apiEndPoint="user/create"
          userType="client"
        /> */}

        <Container>
          <Row>
            {/* Client Name */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder="Enter client name"
                  type="text"
                />
              </Form.Group>
            </Col>

            {/* Brand Name */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  name="brandName"
                  value={formValues.brandName}
                  onChange={handleChange}
                  placeholder="Enter brand name"
                  type="text"
                />
              </Form.Group>
            </Col>

            {/* Email */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="Enter client email"
                  type="text"
                />
              </Form.Group>
            </Col>

            {/* Phone */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                  placeholder="Enter client phone number"
                  type="text"
                />
              </Form.Group>
            </Col>

            {/* Client Status */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Client Status</Form.Label>
                {/* <Form.Control
                  name="clientStatus"
                  value={formValues.clientStatus}
                  onChange={handleChange}
                  placeholder="Enter client status"
                  type="text"
                /> */}
                <Form.Select
                  className="text-capitalize cal-form-dropdown"
                  name="clientStatus"
                  value={formValues.clientStatus}
                  aria-label="Default select example"
                  onChange={handleChange}
                >
                  <option value="" disabled={true}>
                    Select status
                  </option>
                  <option className="text-capitalize" value="active">
                    active
                  </option>
                  <option className="text-capitalize" value="former">
                    former
                  </option>
                  <option className="text-capitalize" value="maintenance">
                    maintenance
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>

            {/* Client Services */}
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label>Client Services</Form.Label>
                <div className="select-element-wrapper cal-form-dropdown">
                  <p onClick={handleToggle}>Select any service</p>
                  <div
                    className={`select-options-wrapper`}
                    style={{ height: isDropDownVisible ? "150px" : "0px" }}
                  >
                    <div
                      className={`select-options-inner-wrapper ${
                        isDropDownVisible ? "visible" : ""
                      }`}
                    >
                      <ul>
                        <li>
                          <input
                            name="Website Development"
                            checked={formValues.clientServices.includes(
                              "Website Development"
                            )}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          Website Development
                        </li>
                        <li>
                          <input
                            name="Performance Marketing"
                            checked={formValues.clientServices.includes(
                              "Performance Marketing"
                            )}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          Performance Marketing
                        </li>
                        <li>
                          <input
                            name="Brand Marketing"
                            checked={formValues.clientServices.includes(
                              "Brand Marketing"
                            )}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          Brand Marketing
                        </li>
                        <li>
                          <input
                            name="Graphics"
                            checked={formValues.clientServices.includes(
                              "Graphics"
                            )}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          Graphics
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Col>

            {/* Dynamically Generated POC Fields */}
            {formValues.clientServices.map((service, index) => (
              <Col sm={12} key={service}>
                <h5>{`POC for ${service}`}</h5>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Point of Contact Name</Form.Label>
                      <Form.Control
                        name={`pocName-${service}`}
                        value={formValues.poc[index]?.name || ""}
                        onChange={(e) =>
                          handlePocChange(e, service, "name", index)
                        }
                        placeholder="Enter contact name"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        name={`pocContact-${service}`}
                        value={formValues.poc[index]?.contact || ""}
                        onChange={(e) =>
                          handlePocChange(e, service, "contact", index)
                        }
                        placeholder="Enter contact number"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name={`pocEmail-${service}`}
                        value={formValues.poc[index]?.email || ""}
                        onChange={(e) =>
                          handlePocChange(e, service, "email", index)
                        }
                        placeholder="Enter email"
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        name={`pocDepartment-${service}`}
                        value={formValues.clientServices[index] || ""}
                        onChange={(e) =>
                          handlePocChange(e, service, "team", index)
                        }
                        placeholder="Enter team"
                        type="text"
                        disabled
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </Col>
            ))}

            <Col sm={12}>
              <CommonButton
                btnName={"Submit"}
                btnClass="common-btn btn-edit"
                onClickAction={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
      )}
    </>
  );
};

export default AddClient;
