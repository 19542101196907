import { createSlice } from "@reduxjs/toolkit";

const globalNotificationReducer = createSlice({
    name: 'globalNotification',
    initialState: {
        notification: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getGlobalNotificationStart: (state) => {
            state.isFetching = true;
        },
        getGlobalNotificationSuccess: (state, action) => {
            state.isFetching = false;
            state.notification = action.payload;
        },
        getGlobalNotificationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add notification actions 
        addGlobalNotificationStart: (state) => {
            state.isFetching = true;
        },

        addGlobalNotificationSuccess: (state, action) => {
            state.isFetching = false;
            const newUser = action.payload;
            state.notification = [...state.notification, newUser];
        },

        addGlobalNotificationFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteGlobalNotificationStart: (state) => {
            state.isFetching = true;
        },

        deleteGlobalNotificationSuccess: (state, action) => {
            state.isFetching = false;
            state.notification.splice(
                state.notification.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteGlobalNotificationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update user actions
        updateGlobalNotificationStart: (state) => {
            state.isFetching = true;
        },

        updateGlobalNotificationSuccess: (state, action) => {
            state.isFetching = false;
            const globalNotificationIndex = state.notification.findIndex(notification => notification._id === action.payload._id);
            if (globalNotificationIndex !== -1) {
                state.notification[globalNotificationIndex] = action.payload;
            }
        },

        updateGlobalNotificationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // update user password actions
        updatePasswordStart : (state) => {
            state.isFetching = true;
            state.error = false;
        },

        updatePasswordSuccess : (state, action) => {
            state.isFetching = false;
            state.notification = action.payload;
        },
        updatePasswordFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getGlobalNotificationStart, getGlobalNotificationSuccess, getGlobalNotificationFailure, deleteGlobalNotificationStart, deleteGlobalNotificationSuccess, deleteGlobalNotificationFailure, addGlobalNotificationFailure, addGlobalNotificationStart, addGlobalNotificationSuccess, updateGlobalNotificationStart, updateGlobalNotificationSuccess, updateGlobalNotificationFailure } = globalNotificationReducer.actions;
export default globalNotificationReducer.reducer;

