import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DynamicForm from "../../../common-components/form/DynamicForm";
import { employeeSchema, oldEmployeeSchema } from "../../../formSchema";
import { serverRequestType } from "../../../config";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";

const UpdateEmployee = () => {
  const { id: employeeId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const isUserFetching = useSelector((state) => state.employee?.isFetching);
  const currentEmployee = useSelector((state) =>
    state.employee?.employees?.find((employee) => employee?._id === employeeId)
  );
  console.log("match in update ==>?", currentEmployee);

  useEffect(() => {
    setInitialValues(currentEmployee);
  }, [currentEmployee]);

  if (isUserFetching) {
    return <h1>Fetching</h1>;
  }

  return (
    // <>
    // {
    initialValues ? (
      <>
        {/* breadcrumb  */}
        <Breadcrumb mainTitle="Update Details" />

        {/* employee details update form */}
        <div className="emp-detail-update-form custom-padding">
          <DynamicForm
            schema={oldEmployeeSchema}
            initialValues={initialValues}
            httpMethod={serverRequestType.PUT}
            apiEndPoint={`user/${employeeId}`}
            buttonName="Update"
          />
        </div>
      </>
    ) : (
      <h1>Loading....</h1>
    )
    // }

    // </>
  );
};

export default UpdateEmployee;
