import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import userReducer from "./userRedux";
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import userRedux from "./userRedux";
import registeredUserRedux from "./registeredUserRedux";
import clientRedux from "./clientRedux";
import employeeRedux from "./employeeRedux";
import projectRedux from "./projectRedux";
import leaveRedux from "./leaveRedux";
import smReportRedux from "./smReportRedux";
import globalNotificationRedux from "./globalNotificationRedux";


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['loginUser'],
}



const rootReducer = combineReducers({ loginUser: userRedux, allUsers : registeredUserRedux, client : clientRedux, employee : employeeRedux, projects : projectRedux, leave : leaveRedux, smReports : smReportRedux, globalNotification : globalNotificationRedux});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({reducer : rootReducer});

//  export default store;


// export let persistor = persistStore(store);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            // serializableCheck : {
            //     ignoreActions : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
        }),
});

export let persistor = persistStore(store);