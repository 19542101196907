import { Col, Container, Row } from "react-bootstrap";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import { newTaskFormSchema } from "../../../../formSchema";
import "./AddTask.css";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const AddTask = () => {
  return (
    <>
      {/* breadcrumb */}
      <Breadcrumb mainTitle="Add New Task" />
      <section className="static-breadcrumb-wrapper">
        <Container className="p-0">
          <Row>
            <Col lg={12}>
              <div className="sb-content-wrapper">
                <h2>Add New Task</h2>
                <p className="m-0">Dashboard&nbsp;/&nbsp;Add New Task</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="task-form-container custom-padding">
        <DynamicForm schema={newTaskFormSchema} />
      </div>
    </>
  );
};

export default AddTask;
