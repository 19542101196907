import { Col, Container, Row } from "react-bootstrap";
import "./CreateTicket.css";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import { newTicketSchema } from "../../../../formSchema";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const CreateTicket = () => {
  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb mainTitle="Create Ticket" />

      <section className="create-ticket-form-container custom-padding">
        <Container>
          <Row>
            <DynamicForm schema={newTicketSchema} />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateTicket;
