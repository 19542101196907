import { Container, Row, Col } from "react-bootstrap";
import "./Tickets.css";
import CommonButton from "../../../../common-components/button/CommonButton";
import TicketsTable from "../../../../common-components/ticket-table/TicketsTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const Tickets = () => {
  // Ticket Data
  const ticketsData = [
    {
      ticketId: "TKT-1237",
      tSubject: "Website Issue",
      tRaisedBy: "Livina",
      priority: "High",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "Pending Reply",
    },
    {
      ticketId: "TKT-1237457",
      tSubject: "Internet issue",
      tRaisedBy: "Livina",
      priority: "Low",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "In Progress",
    },
    {
      ticketId: "TKT-1238957",
      tSubject: "Landing page",
      tRaisedBy: "Livina",
      priority: "Low",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "Completed",
    },
  ];
  return (
    <section className="tickets-container">
      <Container>
        <Row>
          {/* breadcrumb  */}
          <Breadcrumb mainTitle="Tickets" />

          <section className="ticket-table-btn-container">
            <div className="create-ticket-btn custom-padding text-end">
              <Link to="/create-ticket">
                <CommonButton btnClass="common-btn" btnName="Create Ticket" />
              </Link>
            </div>

            <div className="ticket-table-container custom-padding">
              <TicketsTable tickets={ticketsData} />
            </div>
          </section>
        </Row>
      </Container>
    </section>
  );
};

export default Tickets;
