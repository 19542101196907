import { createSlice } from "@reduxjs/toolkit";

const clientReducer = createSlice({
    name: 'project',
    initialState: {
        projects: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getProjectsStart: (state) => {
            state.isFetching = true;
        },
        getProjectsSuccess: (state, action) => {
            state.isFetching = false;
            state.projects = action.payload;
        },
        getProjectsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addProjectStart: (state) => {
            state.isFetching = true;
        },

        addProjectSuccess: (state, action) => {
            state.isFetching = false;
            const newProject = action.payload;
            state.projects = [...state.projects, newProject];
        },

        addProjectFailure: (state) => {
            state.error = true;
        },

        // Delete user actions
        deleteProjectStart: (state) => {
            state.isFetching = true;
        },

        deleteProjectSuccess: (state, action) => {
            state.isFetching = false;
            state.projects.splice(
                state.projects.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteProjectFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        deleteProjectTaskSuccess: (state, action) => {
            state.isFetching = false;
            const { projectId, taskIndex } = action.payload;

            console.log("coming payload ==>", action.payload)

            // Find the index of the project
            const projectIndex = state.projects.findIndex(project => project._id === projectId);
            if (projectIndex !== -1) {
                // Check if taskIndex is valid
                if (taskIndex >= 0 && taskIndex < state.projects[projectIndex].tasks.length) {
                    // Remove the task from the tasks array
                    state.projects[projectIndex].tasks.splice(taskIndex, 1);
                }
            }
        },

        // Update user actions
        updateProjectStart: (state) => {
            state.isFetching = true;
        },

        updateProjectSuccess: (state, action) => {
            console.log("coming payload in side reducer ==>", action)
            state.isFetching = false;
            const projectIndex = state.projects.findIndex(project => project._id === action.payload._id);
            if (projectIndex !== -1) {
                state.projects[projectIndex] = action.payload;
            }
        },

        updateProjectFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getProjectsStart, getProjectsSuccess, getProjectsFailure, deleteProjectStart, deleteProjectSuccess, deleteProjectFailure, deleteProjectTaskSuccess, addProjectFailure, addProjectStart, addProjectSuccess, updateProjectStart, updateProjectSuccess, updateProjectFailure } = clientReducer.actions;
export default clientReducer.reducer;

