import { BarChart } from "@mui/x-charts";
import "./BarGraphReport.css";

const BarGraphReport = ({ xAxisData, seriesData }) => {
  const data = xAxisData.map((label, index) => ({
    name: label,
    value: seriesData[index],
  }));
  return (
    <BarChart
      xAxis={[
        {
          id: "barCategories",
          data: xAxisData, //  dynamic x-axis data
          scaleType: "band",
        },
      ]}
      series={[
        {
          data: seriesData, //  dynamic series data
        },
      ]}
      width={1000}
      height={500}
    />
  );
};

export default BarGraphReport;
