import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../overview-cards/OverviewCards.css";
import { Card, CardBody, CardTitle } from "react-bootstrap";

const OverviewCards = (props) => {
  const cardStyle = {
    backgroundColor: props.bgColor,
  };
  return (
    <Card className={`ov-card-wrapper ${props.customClass}`} style={cardStyle}>
      <CardBody>
        <CardTitle className="ovc-title">{props.OvCardTitle}</CardTitle>
        <Card.Text
          className={`ovc-text ${props.OvCardTitle === "" ? "pt-25" : ""}`}
        >
          {props.OvCardContent}
        </Card.Text>
      </CardBody>
    </Card>
  );
};

export default OverviewCards;
