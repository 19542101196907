import { Container, Row, Stack } from "react-bootstrap";
import "./PieChartReport.css";
import { PieChart } from "@mui/x-charts";
import { useState } from "react";

// const items = [
//   { value: 10, label: "Series A ( no Id )" },
//   { id: "id_B", value: 15, label: "Series B" },
//   { id: "id_C", value: 20, label: "Series C" },
// ];

const formatObject = (obj) => {
  if (obj === null) {
    return "  undefined";
  }
  return JSON.stringify(obj, null, 2)
    .split("\n")
    .map((l) => `  ${l}`)
    .join("\n");
};
const PieChartReport = ({ data, onClick }) => {
  const [identifier, setIdentifier] = useState(null);
  const [id, setId] = useState(undefined);

  const handleClick = (event, itemIdentifier, item) => {
    setId(item.id);
    setIdentifier(itemIdentifier);
    // Call the onClick prop function if provided
    if (onClick) {
      onClick(event, itemIdentifier, item);
    }
  };
  return (
    <section className="report-pie-chart-container">
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <PieChart
            series={[
              {
                data: data,
              },
            ]}
            onClick={handleClick}
            width={400}
            height={200}
            margin={{ right: 200 }}
          />
        </Stack>
      </Container>
    </section>
  );
};

export default PieChartReport;
