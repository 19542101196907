import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./EmpOverview.css";
import HeaderNav from "../sidebar/HeaderNav";
import WelcomeMsg from "../../../common-components/welcome-msg/WelcomeMsg";
import { Col, Container, Row } from "react-bootstrap";
import AnnouncementBar from "../../../common-components/announcement-bar/AnnouncementBar";
import OverviewCards from "../../../common-components/cards/overview-cards/OverviewCards";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import DataTable from "../../../common-components/tasks-table/DataTable";
import Project from "../../../common-components/cards/project-card/Project";
import TicketsTable from "../../../common-components/ticket-table/TicketsTable";
import MeetingCard from "../../../common-components/cards/meeting-card/MeetingCard";
import OnlineMeeting from "../../../common-components/online-meeting/OnlineMeeting";
import { getAllClients, getAllProjects, getGlobalNotification } from "../../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import { userRequest } from "../../../requestMethod";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";

const EmpOverview = () => {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();


  useEffect(() => {
    const organisationId = currentUser?.isUserOrganisationOwner
      ? currentUser._id
      : currentUser?.organisationId;
    getAllClients(dispatch, organisationId);
  }, [dispatch]);

  const { isFetching, clients: allClients } = useSelector(
    (state) => state?.client
  );

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllProjects(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getGlobalNotification(dispatch)
  }, [dispatch]);

  const state = useSelector((state) => state);
  console.log("state from overview page==>", state);

  const [assignedTask, setAssignedTask] = useState(0);
  const [pendingTask, setPendingTask] = useState(0);
  const [upcomingMeetings, setUpcomingMeetings] = useState(0);
  const [assignedClients, setAssignedClients] = useState(0);
  const [assignedProjects, setAssignedProjects] = useState(0);

  // const {isFetching, projects : allProjects } = useSelector((state) => state.projects);


  // useEffect(() => {
  //   const checkWorkStatus = async () => {
  //     try {
  //       const res = await userRequest.get('/attendance/status');
  //       console.log("getting response in status ==>", res)
  //       if (res.status === 200) {
  //         // setIsWorkStart(res.data.isWorkStart);
  //       }
  //     } catch (error) {
  //       console.log("Error checking work status", error);
  //     }
  //   };
  //   checkWorkStatus();
  // }, []);

  const OCData = [
    { number: assignedTask, content: "Assigned Task", bgColor: "var(--LGreen)" },
    { number: pendingTask, content: "Delayed Task", bgColor: "var(--Red)" },
    { number: upcomingMeetings, content: "Meetings", bgColor: "var(--Orange)" },
    { number: assignedClients, content: "Clients", bgColor: "var(--SkyBlue)" },
    { number: assignedProjects, content: "Projects", bgColor: "var(--DAqua)" },
    // { number: "12", content: "Approvals", bgColor: "var(--LGreen)" },
    // { number: "19", content: "Assigned Tasks", bgColor: "var(--SkyBlue)" },
    // { number: "9", content: "Active", bgColor: "var(--Orange)" },
    // { number: "3", content: "Pending", bgColor: "var(--Red)" },
    // {
    //   number: "",
    //   content: "Calender",
    //   bgColor: "var(--DAqua)",
    //   customClass: "pt-30",
    // },
  ];

  const taskData = [
    {
      taskID: "WEB123",
      clientName: "Meritus",
      project: "Portal Redesign",
      priority: "High",
      description: "New Development",
      status: "In Progress",
    },
    {
      taskID: "WEB432",
      clientName: "Visio World",
      project: "Redesign",
      priority: "Mid",
      description: "Upload banners",
      status: "Completed",
    },
    {
      taskID: "BM132",
      clientName: "Nutroyumm",
      project: "Redesign",
      priority: "Low",
      description: "Redesign the homepage",
      status: "Pending",
    },
    {
      taskID: "C132",
      clientName: "Meritus",
      project: "Website Content",
      priority: "High",
      description: "Website content",
      status: "Completed",
    },
    {
      taskID: "PM987",
      clientName: "3 Roads",
      project: "Project Management",
      priority: "Low",
      description: "Develop landing page",
      status: "Completed",
    },
    {
      taskID: "G0871",
      clientName: "Modern School Fbd",
      project: "Design",
      priority: "Mid",
      description: "New development",
      status: "In Progress",
    },
  ];

  console.log("tasks table data ==>", taskData);

  const projectsData = [
    {
      status: "pending",
      name: "Nutroyumm redesign",
      projectProgress: "65",
      delDate: "March 5",
      priority: "low",
    },
    {
      status: "In Progress",
      name: "meritus",
      projectProgress: 95,
      delDate: "april 26",
      priority: "Mid",
    },
    {
      status: "completed",
      name: "Hamaan",
      projectProgress: 100,
      delDate: "march 15",
      priority: "high",
    },
  ];

  // Ticket Data
  const ticketsData = [
    {
      ticketId: "TKT-1237",
      tSubject: "Website Issue",
      tRaisedBy: "Livina",
      priority: "High",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "Pending Reply",
    },
    {
      ticketId: "TKT-1237457",
      tSubject: "Internet issue",
      tRaisedBy: "Livina",
      priority: "Low",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "In Progress",
    },
    {
      ticketId: "TKT-1238957",
      tSubject: "Landing page",
      tRaisedBy: "Livina",
      priority: "Low",
      tCreated: "5 Feb, 2024",
      lastReply: "5 Feb, 2024",
      status: "Completed",
    },
  ];

  // meeting data
  const meetingData = [
    {
      status: "upcoming",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
    {
      status: "today",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
  ];

  const { isProjectsFetching, projects: allProjects } = useSelector(
    (state) => state.projects
  );

  // const { isFetching, clients: allClients } = useSelector(
  //   (state) => state?.client
  // );
  // const { isProjectsFetching, projects: allProjects } = useSelector(
  //   (state) => state.projects
  // );

  const allAssignedTasksofProjects = allProjects?.reduce((acc, project) => {
    const projectTasks = project.tasks.map((task) => ({
      ...task,
      projectTitle: project.title,
    }));
    return [...acc, ...projectTasks];
  }, []);

  const allPendingTasksofProjects = allProjects?.reduce((acc, project) => {
    const projectTasks = project.tasks.map((task) => {
      // if(task == "pending")
      // ...task,
      // projectTitle: project.title,
    });
    return [...acc, ...projectTasks];
  }, []);





  console.log("allTasksofProjects ==>", allAssignedTasksofProjects)

  useEffect(() => {
    setAssignedProjects(allProjects?.length)
    setAssignedTask(allAssignedTasksofProjects?.length)
  }, [allProjects]);

  useEffect(() => {
    setAssignedClients(allClients?.length)
  }, [allClients]);



  console.log("allClients from overview==>", allClients)



  // const state = useSelector(
  //   (state) => state);

  // console.log("state from overview ==>", state)

  const { notification, isFetching: isGlobalNotificationFetching } =
    useSelector((state) => state.globalNotification);
  // const { isFetching, not: allProjects } = useSelector(
  //   (state) => state.
  // );

  const [topProjects, setTopProjects] = useState([]);

  useEffect(() => {
    const threeProjects = allProjects?.filter((item, index) => {
      if (index < 3) {
        return item
      }
    });

    setTopProjects(threeProjects);
  }, [allProjects]);

  // const [notification, setNotification] = useState("");

  console.log("topProjects ===>", topProjects);

  if (isProjectsFetching || isGlobalNotificationFetching) {
    return <MiniLoader />;
  }

  // const currentLocation = useLocation();
  // console.log("current URL ========>>>>", currentLocation);
  return (
    <div className="emp-overview-wrapper">
      <div className="overview-nav-content">
        <AnnouncementBar pageType="overview" notification={notification} />
        <WelcomeMsg />

        {/* overview cards section  */}
        <div className="ov-cards-wrapper custom-padding">
          <Container>
            <Row>
              {OCData.map((item, index) => (
                <Col key={index} lg={2} md={4} xs={6} className="OvCardCol">
                  <OverviewCards
                    OvCardTitle={item.number}
                    OvCardContent={item.content}
                    bgColor={item.bgColor}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        {/* <Breadcrumb /> */}
        {/* Projects breadcrumb */}
        <Breadcrumb mainTitle="Projects" />

        {/* Projects  */}
        <div className="custom-padding">
          <Project projects={topProjects} />
        </div>

        {/* Tickets */}
        <Breadcrumb mainTitle="Open Tickets" />

        <div className="emp-overview-tt-wrapper custom-padding">
          <TicketsTable tickets={ticketsData} />
        </div>

        {/* Meetings  */}
        {/* <Breadcrumb mainTitle="Upcoming Meetings" />
        <MeetingCard meetings={meetingData} />
        <OnlineMeeting /> */}
      </div>
    </div>
  );
};

export default EmpOverview;
