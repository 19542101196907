export const departmentName = [
  "Web Department",
  "Brand Marketing",
  "Performance Marketing",
  "Content Department",
  "Graphic Department",
  "Account Department",
];

// export const designationName = ['Trainee', 'Executve','Frontend Developer', 'Backend Developer',];
export const designationName = [
  {
    department: "Web Department",
    designations: [
      "Trainee",
      "Full Stack Developer",
      "Frontend Developer",
      "Backend Developer",
    ],
  },
  {
    department: "Brand Marketing",
    designations: ["Trainee", "Team Lead", "Executive"],
  },
  {
    department: "Performance Marketing",
    designations: ["Trainee", "Team Lead", "Executive"],
  },
  {
    department: "Content Department",
    designations: ["Trainee", "Team Lead", "Executive"],
  },
  {
    department: "Graphic Department",
    designations: [
      "Trainee",
      "Team Lead",
      "Graphic Designer",
      "Sr. Graphic Designer",
    ],
  },
  {
    department: "Account Department",
    designations: ["Trainee", "Accounting Manager", "Executive"],
  },
];

export const newEmployeeTypes = ["intern", "full-time"];
export const oldEmployeeTypes = [
  "intern",
  "full-time",
  "former",
  "notice-period",
];
// export const employeeLevel = ['employee', 'team-head',];
export const employeeLevel = ["employee", "team-head"];
export const clientStatus = ["active", "former", "maintenance"];

export const clientServices = [
  "website development",
  "performance marketing",
  "brand marketing",
  "graphics",
  "content management",
];
// export const clientStatus = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance"
// }

export const intentOptionForUserCalenderForm = [
  "Brand",
  "Knowledge Sharing",
  "Entertain",
  "Inspire",
  "Convince",
  "Sales",
  "Engage",
  "Special Days",
];

export const typeOfPostOptionsForUserCalenderForm = [
  "Single Image",
  "Carousel",
  "Reel",
  "Video",
  "Text",
  "Poll",
];

export const statusOptionsForUserCalenderForm = [
  "Rejected",
  "Delayed",
  "Posted",
  "Scheduled",
  "Approved",
  "Under Process",
];

export const serverRequestType = {
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const priority = ["High", "Med", "Low"];
export const status = [
  "Suspended",
  "In Progress",
  "Delayed",
  "Completed",
  "In Active",
  "Paused",
];
// export const status = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance",
//   OPEN: "open",
//   INPROGRESS: "in-progress",
//   CLOSED: "closed",
//   COMPLETED: "completed"
// }
export const leaveType = ["Full Day Leave", "Half Day Leave", "Work From Home"];

export const validate = (values, schema) => {
  const errors = {};

  schema?.forEach((field) => {
    if (
      field?.validation?.includes("required") &&
      (!values[field.name] || values[field.name].length < 1)
    ) {
      errors[field.name] = "This field is required";
    }
    if (field?.validation?.includes("email") && values[field.name]) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(values[field.name])) {
        errors[field.name] = "Invalid email address";
      }
    }
  });

  return errors;
};

export const deepEqualCheckForTwoArray = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  for (let i = 0; i < arr1?.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
  }
  return true;
};

export const getStatus = (statusType, status) => {
  console.log(status);
  if (statusType === "task") {
    switch (status) {
      case "in progress":
        return "In Progress";
      case "completed":
        return "Completed";
      case "suspended":
        return "Suspended";
      case "delayed":
        return "Delayed";
      case "in active":
        return "Inactive";
      case "paused":
        return "Paused";
      default:
        return "Unassigned";
    }
  }
  return "Invalid Status Type";
};

export const changeDateFormat = (date) => {
  const fetchedDate = new Date(date);
  const formattedDate = fetchedDate.toLocaleDateString();
  return formattedDate;
};
