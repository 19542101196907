import { Col, Container, Row } from "react-bootstrap";
import "./AllTasksTable.css";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../../common-components/button/CommonButton";
import DataTable from "../../../common-components/tasks-table/DataTable";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleUp, FaArrowUp, FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { useState } from "react";

const AllTasksTable = () => {

  const projects = useSelector((state) => state.projects?.projects);

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const [sortCriteria, setSortCriteria] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const isFieldSorted = (field) => sortCriteria?.includes(field);
  const [selectedProject, setSelectedProject] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  // Sorting function for multi-level sorting
  // const handleSort = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key && sortConfig.direction === "asc") {
  //     direction = "desc";
  //   }
  //   setSortConfig({ key, direction });
  // };

  // Sorting function for multi-level sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };


  // Filter tasks based on the selected project
  const filterTasksByProject = () => {
    if (selectedProject) {
      return projects?.reduce((acc, project) => {
        if (project._id === selectedProject._id) {
          const projectTasks = project.tasks.map((task) => ({
            ...task,
            projectTitle: project.title,
          }));
          return [...acc, ...projectTasks];
        }
        return acc;
      }, []);
    }
    // Return all tasks if no project is selected
    return projects?.reduce((acc, project) => {
      const projectTasks = project.tasks.map((task) => ({
        ...task,
        projectTitle: project.title,
      }));
      return [...acc, ...projectTasks];
    }, []);
  };

  // Apply sorting to filtered tasks
  // const filteredTasks = filterTasksByProject()?.sort((a, b) => {
  //   if (sortConfig.title) {
  //     return a.projectTitle.localeCompare(b.projectTitle);
  //   }
  //   if (sortConfig.deadline) {
  //     return new Date(a.endDate) - new Date(b.endDate);
  //   }
  //   if (sortConfig.priority) {
  //     return a.priority.localeCompare(b.priority);
  //   }
  //   return 0;
  // });

  // Apply sorting to filtered tasks
  const filteredTasks = filterTasksByProject()?.sort((a, b) => {
    if (!sortConfig.key) {
      return 0; // No sorting criteria set
    }

    let order = sortConfig.direction === "asc" ? 1 : -1;

    if (sortConfig.key === "endDate") {
      return order * (new Date(a.endDate) - new Date(b.endDate));
    }

    if (sortConfig.key === "priority") {
      // Assuming priorities are like "low", "mid", "high", you can define a priority order
      const priorityOrder = { low: 1, mid: 2, high: 3 };
      return order * (priorityOrder[a.priority] - priorityOrder[b.priority]);
    }

    if (sortConfig.key === "projectTitle") {
      return order * a.projectTitle.localeCompare(b.projectTitle);
    }

    return 0;
  });


  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Tasks" />
      <div className="action-btn-wrapper custom-padding pb-0">
        <div className="manage-project-dropdown">
          <button className="btn-edit" onClick={handleToggleDropdown}>
            {selectedProject ? selectedProject.title : "Sort By Project"}
            {isDropdownVisible ? <FaAngleUp className="m-0" /> : <FaAngleDown className="m-0" />}
          </button>
          {isDropdownVisible && (
            <ul className="dropdown-list">
              <li
                key="all"
                className="dropdown-item"
                onClick={() => setSelectedProject(null)}
              >
                All Projects
              </li>
              {projects?.map((project, index) => (
                <li
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleSelectProject(project)}
                >
                  {project.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Sort by Deadline */}
        <div className="manage-project-dropdown">
          <button className="btn-edit" onClick={() => handleSort("endDate")}>
            Sort by Deadline
            {sortConfig.key === "endDate" && (
              sortConfig.direction === "asc" ?
                <FaLongArrowAltUp className="m-0" /> :
                <FaLongArrowAltDown className="m-0" />
            )}
            {/* {sortConfig.direction === "asc" ? <FaLongArrowAltUp className="m-0" /> : <FaLongArrowAltDown className="m-0" />} */}
            {/* {isFieldSorted("endDate") ? <FaLongArrowAltUp className="m-0" /> : <FaLongArrowAltDown className="m-0" />} */}
          </button>
        </div>

        {/* Sort by Priority */}
        <div className="manage-project-dropdown">
          <button className="btn-edit" onClick={() => handleSort("priority")}>
            Sort by Priority
            {sortConfig.key === "priority" && (
              sortConfig.direction === "asc" ?
                <FaLongArrowAltUp className="m-0" /> :
                <FaLongArrowAltDown className="m-0" />
            )}
            {/* {isFieldSorted("priority") ? <FaLongArrowAltUp className="m-0" /> : <FaLongArrowAltDown className="m-0" />} */}
          </button>
        </div>
      </div>
      {/* Tasks table */}
      <div className="task-table-container custom-padding">
        <DataTable tasks={filteredTasks} />
      </div>
    </>
  );
};

export default AllTasksTable;
