import React from "react";
import DynamicForm from "../../../common-components/form/DynamicForm";
import { employeeSchema, newEmployeeSchema } from "../../../formSchema";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";

const AddEmployee = () => {
  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Add Employee" />

      <div className="add-emp-form-wrapper custom-padding">
        <DynamicForm
          schema={newEmployeeSchema}
          httpMethod="post"
          apiEndPoint="user/create"
        />
      </div>
    </>
  );
};

export default AddEmployee;
