import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./TicketsTable.css";
import { Col, Container, Row, Table } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";

const TicketsTable = ({ tickets }) => {
  const getColorForPriority = (priority) => {
    switch (priority) {
      case "High":
        return "var(--Red)";

      case "Mid":
        return "var(--Orange)";

      case "Low":
        return "var(--Green)";

      default:
        return "#FFFFFF";
    }
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "Pending Reply":
        return "#EE534F";

      case "In Progress":
        return "#FEB056";

      case "Completed":
        return "#A2CD48";

      default:
        return "#FFFFFF";
    }
  };
  return (
    <section className="tickets-table-wrapper">
      <Container>
        <Row>
          <table className="tickets-data">
            <thead>
              <tr>
                <th>#</th>
                <th>ticket id</th>
                <th>Subject</th>
                <th>Raised By</th>
                <th>Priority</th>
                <th>Created On</th>
                <th>Last Replied</th>
                <th>Status</th>
                <th>Reply</th>
              </tr>
            </thead>

            <tbody>
              {tickets.map((ticket, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{ticket.ticketId}</td>
                    <td>{ticket.tSubject}</td>
                    <td>{ticket.tRaisedBy}</td>
                    <td style={{ color: getColorForPriority(ticket.priority) }}>
                      {ticket.priority}
                    </td>
                    <td>{ticket.tCreated}</td>
                    <td>{ticket.lastReply}</td>
                    <td style={{ color: getColorForStatus(ticket.status) }}>
                      {ticket.status}
                    </td>
                    <td>
                      <IoEyeOutline />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      </Container>
    </section>
  );
};

export default TicketsTable;
