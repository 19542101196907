import React from 'react';
import './MiniLoader.css';

const MiniLoader = () => {
    return (
        <section className='mini-loader-section'>
            <span class="loader-1"> </span>
        </section>
    )
}

export default MiniLoader
