import { Col, Container, Row } from "react-bootstrap";
import "./PerformanceMarketingStats.css";
import HeaderNav from "../../../emp-dashboard/sidebar/HeaderNav";
import AnnouncementBar from "../../../../common-components/announcement-bar/AnnouncementBar";
import GeneralTable from "../../../../common-components/gen-table/GeneralTable";
import PieChartReport from "../../../../common-components/pie-chart/PieChartReport";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const PerformanceMarketingStats = () => {
  // headers for status table
  const headersData = ["#", "performance marketing", "status"];
  // data for status table
  const pmStatus = [
    {
      "#": "1",
      "performance marketing": "Campaigns Analysis (Google+ Facebook)",
      status: "Active",
    },
    {
      "#": "2",
      "performance marketing": "Campaigns Optimization (Google+Facebook)",
      status: "Active",
    },
  ];

  const pmHeadersData = [
    "reach",
    "impressions",
    "CTR",
    "clicks",
    "CPC (₹)",
    "CPL (₹)",
    "total leads",
    "conversion rate",
    "total spent (₹)",
  ];

  const pmAdsStats = [
    {
      date: "2024-04-15",
      reach: "576,339",
      impressions: "1,840,051",
      CTR: "0.13%",
      clicks: "2,444",
      "CPC (₹)": "12.33",
      "CPL (₹)": "478.44",
      "total leads": "63",
      "conversion rate": "2.57%",
      "total spent (₹)": "30142.03",
    },
  ];

  //   facebook ads leads data
  const fdAdLeadsHeader = [
    "#/%",
    "qualified",
    "response pending",
    "non qualified",
    "repeat",
    "not contacted",
  ];
  const fbLeadsDataNo = [
    {
      date: "2024-04-15",
      "#/%": "#",
      qualified: "5",
      "response pending": "21",
      "non qualified": "9",
      repeat: "1",
      "not contacted": "27",
    },
    {
      date: "2024-04-15",
      "#/%": "%",
      qualified: "7.93",
      "response pending": "33.33",
      "non qualified": "14.28",
      repeat: "1.58",
      "not contacted": "42.85",
    },
  ];

  // google ads leads data
  const googleHeadersData = [
    "impressions",
    "CTR",
    "clicks",
    "CPC (₹)",
    "CPL (₹)",
    "total leads",
    "conversion rate",
    "total spent (₹)",
  ];

  const googleLeadsDataNo = [
    {
      impressions: "76,835",
      CTR: "2.07%",
      clicks: "1590",
      "CPC (₹)": "11.73",
      "CPL (₹)": "690.01",
      "total leads": "27",
      "conversion rate": "1.69%",
      "total spent (₹)": "18630.35",
    },
  ];

  // Google leaads stats
  const googleLeadsHeader = [
    "#/%",
    "qualified",
    "response pending",
    "non qualified",
    "repeat",
    "not contacted",
  ];

  const googleLeadsStats = [
    {
      date: "2024-04-15",
      "#/%": "#",
      qualified: "2",
      "response pending": "6",
      "non qualified": "4",
      repeat: "6",
      "not contacted": "14",
    },
    {
      date: "2024-04-15",
      "#/%": "%",
      qualified: "7.43",
      "response pending": "22.22",
      "non qualified": "14.81",
      repeat: "22.22",
      "not contacted": "51.85",
    },
  ];


  const tableData = [
    {
      header : "Date",
      field : "name"
    },
    {
      header : "Reach",
      field : "name"
    },
    {
      header : "Profile Visit",
      field : "name"
    },
    {
      header : "Impression",
      field : "name"
    },
  ]

  return (
    <div className="performance-stats-conatiner">
      {/* Performance reports breadcrumb */}
      <Breadcrumb mainTitle="Performance Marketing & SEO (Education)" />

      {/* performance marketing status table  */}
      <section className="pm-status-table-container custom-padding">
        <Container>
          <Row>
            <GeneralTable headers={headersData} smData={pmStatus} />
          </Row>
        </Container>
      </section>

      {/* performance marketing facebook ads stats table  */}
      {/* breadcrumb */}
      <Breadcrumb mainTitle="Facebook Ads" />

      {/* table with data */}
      <section className="pm-fb-ads-stats-container custom-padding">
        <Container>
          <Row>
            <GeneralTable headers={pmHeadersData} smData={pmAdsStats} />
          </Row>
        </Container>
      </section>

      {/* TOTAL LEADS TABLE AND GRAPH  */}
      {/* breadcrumb */}

      <Breadcrumb mainTitle="Total Leads" />

      {/* Facebook Ads */}
      <section className="leads-table-container custom-padding">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading">
                <h2>Facebook Ads:</h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center custom-padding">
            <Col lg={8}>
              {/* leads-table number */}
              <GeneralTable headers={fdAdLeadsHeader} smData={fbLeadsDataNo} />
            </Col>
            <Col lg={4}>
              <PieChartReport
                data={[
                  { value: 7.93, label: "Qualified" },
                  { id: "id_B", value: 33.33, label: "Response Pending" },
                  { id: "id_C", value: 14.28, label: "Non Qualified" },
                  { id: "id_D", value: 1.58, label: "Repeat" },
                  { id: "id_E", value: 42.85, label: "Not Contacted" },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* performance marketing google ads stats table  */}
      {/* breadcrumb */}
      <Breadcrumb mainTitle="Google Ads" />

      {/* table with data */}
      <section className="pm-fb-ads-stats-container custom-padding">
        <Container>
          <Row>
            <GeneralTable
              headers={googleHeadersData}
              smData={googleLeadsDataNo}
            />
          </Row>
        </Container>
      </section>

      {/* Google Ads leads data  */}
      {/* breadcrumb */}
      <Breadcrumb mainTitle="Total Leads" />

      {/* table with data */}
      <section className="leads-table-container custom-padding">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading">
                <h2>Google Ads:</h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center custom-padding">
            <Col lg={8}>
              {/* leads-table number */}
              <GeneralTable
                headers={googleLeadsHeader}
                smData={googleLeadsStats}
              />
            </Col>
            <Col lg={4}>
              <PieChartReport
                data={[
                  { value: 7.4, label: "Qualified" },
                  { id: "id_B", value: 22.22, label: "Response Pending" },
                  { id: "id_C", value: 14.81, label: "Non Qualified" },
                  { id: "id_D", value: 22.22, label: "Repeat" },
                  { id: "id_E", value: 51.85, label: "Not Contacted" },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PerformanceMarketingStats;
